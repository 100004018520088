import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import ViewModal from "./View/ViewModal";

const ManageConsultantsChild = () => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const [filterText, setFilterText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    getDataFromServer();
  }, []);

  useEffect(() => {
    if (filterText === "") {
      setFilteredData(data);
    } else {
      const lowercasedFilter = filterText.toLowerCase();
      const filtered = data.filter(
        (entry) =>
          entry.name.toLowerCase().includes(lowercasedFilter) ||
          entry.mobile.toLowerCase().includes(lowercasedFilter) ||
          entry.organization.toLowerCase().includes(lowercasedFilter) ||
          entry.email.toLowerCase().includes(lowercasedFilter)
      );
      setFilteredData(filtered);
    }
  }, [filterText, data]);

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/user/delete/${row?._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "ManageConsultantsChild", "/consultants");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/user/consultants`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data.consultants; // Access consultants array from the response data
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data?.member_title} ${data?.first_name} ${data?.last_name}`,
            email: data?.email,
            mobile: data?.mobile,
            organization: data?.organization,
            account_status: data?.account_status,
          };
        });
        setData(updatedData); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleViewModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 190,
      filterable: true,
      renderCell: (params) => (
        <div className="flex items-center">
          <div
            className={`w-2 h-2 rounded-full mr-2 ${
              params.row.account_status ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
          {params.value}
        </div>
      ),
    },
    {
      field: "organization",
      headerName: "Organization",
      width: 300,
      filterable: true,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 180,
      filterable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 180,
      filterable: true,
    },
  ];

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateModal(true);
  };

  // Function to clear the filter text
  const clearFilter = () => {
    setFilterText("");
  };

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex justify-end w-full mb-4">
        <button
          className="flex items-center p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          onClick={toggleAddModal}
        >
          <i
            className="mr-2 mdi mdi-account-plus"
            style={{ fontSize: "18px" }}
          ></i>
          Add Consultant
        </button>
      </div>
      <div className="w-full mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Filter by Name, Mobile, Organization, or Email"
            className="w-full p-2 pl-10 border rounded"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ paddingRight: "40px" }}
          />
          <i
            className="absolute text-gray-500 transform -translate-y-1/2 mdi mdi-magnify left-3 top-1/2"
            style={{ fontSize: "20px" }}
          ></i>
          {filterText && (
            <button
              onClick={clearFilter}
              className="absolute inset-y-0 flex items-center text-gray-500 right-3 hover:text-gray-700"
              style={{ top: "50%", transform: "translateY(-50%)" }}
            >
              <i
                className="mdi mdi-close-circle"
                style={{ fontSize: "24px" }}
              ></i>
            </button>
          )}
        </div>
      </div>
      <h1 className="text-2xl font-semibold text-gray-800">Consultants</h1>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showViewModal && (
          <Modal onClose={toggleViewModal}>
            <ViewModal currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showAddModal && (
          <Modal onClose={toggleAddModal}>
            <AddModal
              setShowAddModal={setShowAddModal}
              getDataFromServer={getDataFromServer}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showUpdateModal && (
          <Modal onClose={toggleUpdateModal}>
            <UpdateModal
              setShowUpdateModal={setShowUpdateModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={filteredData}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Consultants"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default ManageConsultantsChild;
