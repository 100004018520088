import React from "react";
import { ArrowLeft, ArrowRight } from "react-feather";
import { FaUserInjured, FaHandHoldingHeart } from "react-icons/fa";
const MatchCardComponent = ({
  title,
  person,
  bgColor,
  textColor,
  code,
  consultant,
  person_type,
}) => {
  return (
    <div className={`${bgColor} rounded-lg p-4 shadow-md w-full md:w-5/12 `}>
      <div className="flex items-center mb-4 ">
        <div
          className={`p-3 rounded-full ${
            person_type === "R"
              ? "bg-white border border-indigo-800"
              : "bg-white border border-green-800"
          } mr-4 `}
        >
          {person_type === "R" ? (
            <FaUserInjured className="w-8 h-8 text-indigo-500" />
          ) : (
            <FaHandHoldingHeart className="w-8 h-8 text-green-500" />
          )}
        </div>
        <h3 className={`text-lg font-semibold ${textColor}`}>{title}</h3>
      </div>
      <div className="space-y-2">
        <InfoItem label="Name" value={getFullName(person)} />
        <InfoItem label="Code" value={code} />
        <InfoItem label="Blood Group" value={getBloodGroup(person)} />
        <InfoItem label="Gender" value={getGender(person)} />
        <InfoItem label="Consultant" value={consultant} />
      </div>
    </div>
  );
};

export const ArrowIndicator = () => (
  <div className="flex items-center justify-center py-2 md:py-0">
    <ArrowLeft className="mr-1 text-indigo-500" size={20} />
    <ArrowRight className="ml-1 text-indigo-500" size={20} />
  </div>
);

const InfoItem = ({ label, value }) => (
  <p className="flex justify-between text-sm">
    <span className="font-medium">{label}:</span>
    <span className="text-gray-700">{value}</span>
  </p>
);

const getFullName = (person) => {
  if (person.name) return person.name;
  return `${person.member_title || ""} ${
    person.donor_name || person.recipient_name || ""
  } ${person.last_name || ""}`.trim();
};

const getBloodGroup = (person) => {
  return person.bloodgroup || person.blood_group?.label || "N/A";
};

const getGender = (person) => {
  return person.gender_label || person.gender?.label || "N/A";
};

export default MatchCardComponent;
