import React from "react";
import { Typography, Grid, Paper, Box } from "@mui/material";

const ViewModal = ({ currentRecord }) => {
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <Paper elevation={3} sx={{ p: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            {currentRecord.name}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Personal Information</Typography>
          <Box sx={{ ml: 2 }}>
            <Typography>First Name: {currentRecord.first_name}</Typography>
            <Typography>Last Name: {currentRecord.last_name}</Typography>
            <Typography>Email: {currentRecord.email}</Typography>
            <Typography>Mobile: {currentRecord.mobile}</Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Administrative Details</Typography>
          <Box sx={{ ml: 2 }}>
            <Typography>Role: {currentRecord.role}</Typography>
            <Typography>User Type: {currentRecord.user_type}</Typography>
            <Typography>Organization: {currentRecord.organization}</Typography>
            <Typography>
              Subscription Type: {currentRecord.subscription_type}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Account Status</Typography>
          <Box sx={{ ml: 2 }}>
            <Typography>
              Active: {currentRecord.account_status ? "Yes" : "No"}
            </Typography>
            <Typography>
              Yearly Renewal: {currentRecord.yearly_renewal ? "Yes" : "No"}
            </Typography>
            <Typography>
              Payment Status: {currentRecord.payment_status || "N/A"}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">Timestamps</Typography>
          <Box sx={{ ml: 2 }}>
            <Typography>
              Created At: {formatDate(currentRecord.createdAt)}
            </Typography>
            <Typography>
              Updated At: {formatDate(currentRecord.updatedAt)}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ViewModal;
