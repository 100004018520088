import React, { useState } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";
import GeographicalComponent from "../../../components/GeographicalComponent/GeographicalComponent";

const AddModal = ({ getDataFromServer, setShowAddModal }) => {
  let navigate = useNavigate();

  // Error Logging Service & Sanitize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);

  const [formData, setFormData] = useState({
    hospital_name: "",
    contactNumber: "",
    email: "",
    registrationNumber: "",
    country: "",
    state: "",
    city: "",
    address: "",
    comments: "",
  });

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();

    // ... existing handleAddSubmit logic ...
    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/hospitals/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Hospital Created Successfully.");
        getDataFromServer();
        setShowAddModal(false);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "AddModal", "/hospitals/add");
      });
  };

  return (
    <div className="w-full p-3 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-semibold">Add New Hospital</h2>
      <form className="space-y-4" onSubmit={handleAddSubmit}>
        <div className="md:flex md:space-x-4">
          <div className="w-full">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="hospital_name"
            >
              Hospital Name
            </label>
            <input
              type="text"
              name="hospital_name"
              id="hospital_name"
              value={formData.hospital_name}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="contactNumber"
            >
              Contact Number
            </label>
            <input
              type="text"
              name="contactNumber"
              id="contactNumber"
              value={formData.contactNumber}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
          <div className="w-full md:w-1/2">
            <label className="block mb-1 text-sm font-medium" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
        </div>
        <div className="w-full">
          <label
            className="block mb-1 text-sm font-medium"
            htmlFor="registrationNumber"
          >
            Registration Number
          </label>
          <input
            type="text"
            name="registrationNumber"
            id="registrationNumber"
            value={formData.registrationNumber}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <GeographicalComponent
          setFormData={setFormData}
          formData={formData}
          handleChange={handleChange}
        />
        <div className="w-full">
          <label className="block mb-1 text-sm font-medium" htmlFor="address">
            Comments
          </label>
          <textarea
            name="comments"
            id="comments"
            value={formData.comments}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            rows={3}
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 text-white bg-gray-500 rounded-md shadow-sm btn btn-secondary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={() => setShowAddModal(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm btn btn-primary hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Hospital
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddModal;
