import React, { useEffect, useState, useRef } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import ViewModal from "./View/ViewModal";
import ManageRecipientClinical from "../RecipientClinical/ManageRecipientClinical";

import ClinicalDataStatus from "./ClinicalDataStatus";
import { useLocalStorage } from "../hooks/useLocalStorage";

const ManageRecipientsChild = () => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showClinicalModal, setShowClinicalModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [unfilteredData, setUnfilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [donors, setDonors] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [lastViewedId, setLastViewedId] = useLocalStorage(
    "lastViewedRecipientId",
    null
  );

  const [{ errorLogApi, user }] = useAtom(gbState);
  const searchRef = useRef();

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const toggleClinicalModal = () => {
    setShowClinicalModal(!showClinicalModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/recipients/delete/${row?._id}`,
        config
      )
      .then((response) => {
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "ManageRecipientsChild", "/recipients");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/recipients`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then((response) => {
        const oldData = response.data || []; // Ensure oldData is an array
        console.log(oldData);
        const updatedData = oldData.map((data) => ({
          ...data,
          id: data._id,
          name: `${data?.member_title ? data?.member_title : ""} ${
            data?.recipient_name
          } ${data?.last_name}`,
          gender_label: data?.gender?.label,
          gender: data?.gender,
          blood_group: data?.blood_group,
          bloodgroup: data?.blood_group?.label,
          recipient_code: data?.recipient_code,
        }));
        setData(updatedData);
        setUnfilteredData(updatedData);
      })
      .catch((err) => {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  useEffect(() => {
    if (lastViewedId && data.length > 0) {
      const index = data.findIndex((item) => item.id === lastViewedId);
      if (index !== -1) {
        // Scroll to the last viewed row
        setTimeout(() => {
          const gridElement = document.querySelector(".MuiDataGrid-root");
          if (gridElement) {
            gridElement.scrollTo({
              top: index * 52, // Approximate height of each row
              behavior: "smooth",
            });
          }
        }, 100);
      }
    }
  }, [data, lastViewedId]);

  const handleCrossMatch = async (row) => {
    getDonors(row);
  };

  const getDonors = (row) => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/donors/${row.id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };
    axios(config)
      .then((response) => {
        const oldData = response.data;
        console.log(oldData);
        if (oldData?.length) {
          // navigate("/cross-match", { state: row });
          navigate("/v1/cross-match", { state: row });
        } else {
          return toast.error(
            `No Donors Found for crossmatch. 
            You should have a (Recipient, Donor) pair to crossmatch with another pair!
            Add a Donor for your recipient.
            `
          );
        }
        setDonors(oldData);
      })
      .catch((err) => {
        console.log("error api call ", err?.response?.data);
      });
  };

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateModal(true);
  };

  const handleSelectionChange = (selectionModel) => {
    setSelectedIds(selectionModel);
  };

  const deleteMultipleRecords = () => {
    if (!selectedIds?.length) return toast.error("Please select Record(s)");

    confirmAlert({
      title: "",
      message: "Are you sure to delete Recipient(s) ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecordInBulk(),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteRecordInBulk = () => {
    if (!selectedIds?.length) return toast.error("Please select Record(s)");
    selectedIds.forEach((id) => {
      let userToken = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${userToken}` },
      };
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/recipients/delete/${id}`,
          config
        )
        .then((response) => {
          getDataFromServer();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data
              ? error?.response?.data?.error
              : "An error occurred"
          );
          errorLogApi(error, "ManageRecipientsChild", "/recipients");
        });
    });
  };

  const handleRowClick = (params) => {
    setLastViewedId(params.id);
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 115,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleViewModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Clinical Details">
              <div
                onClick={() =>
                  navigate("/recipient/clinical/add", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-plus-circle"
                  style={{
                    fontSize: "16px",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Manage Donor">
              <div onClick={() => navigate("/donors", { state: params.row })}>
                <i
                  className="mdi mdi-account-star"
                  style={{
                    fontSize: "18px",
                    color: "green",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Perform crossmatch">
              <div onClick={() => handleCrossMatch(params.row)}>
                <i
                  className="mdi mdi-account-switch-outline"
                  style={{
                    fontSize: "18px",
                    color: "purple",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "name_f",
      headerName: "Name",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 220,
      renderCell: (params) => (
        <div
          className={`w-full ${params.id === lastViewedId ? "font-bold" : ""}`}
        >
          <ClinicalDataStatus record={params.row} />
        </div>
      ),
    },
    {
      field: "recipient_code",
      headerName: "Code",
      width: 130,
    },
    {
      field: "gender_label",
      headerName: "Gender",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "bloodgroup",
      headerName: "Blood Group",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 120,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 240,
    },
  ];

  const handleSearchChange = ({ currentTarget: input }) => {
    setSearchText(input.value);
  };

  const handleClearSearch = () => {
    setSearchText(""); // Clear the search text
    setData(unfilteredData); // Reset the filtered data
    searchRef.current.focus(); // Optionally, refocus the search input
  };

  useEffect(() => {
    if (unfilteredData) {
      const searchQuery = searchText?.trim().toLowerCase() || "";

      if (searchQuery.length > 0) {
        const filteredData = unfilteredData.filter(
          (d) =>
            d.name.toLowerCase().includes(searchQuery) ||
            d.recipient_code.toLowerCase().includes(searchQuery) ||
            d.email.toLowerCase().includes(searchQuery)
        );
        setData(filteredData);
      } else {
        setData(unfilteredData);
      }
    }
  }, [searchText, unfilteredData]);

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="p-6 mt-3 mb-3 shadow-xl bg-gradient-to-r from-blue-50 to-indigo-50 rounded-xl">
        <div className="flex justify-end w-full space-x-2">
          <button
            className="flex items-center px-3 py-2 text-sm text-white rounded bg-gradient-to-r from-red-500 to-red-500 hover:from-red-600 hover:to-red-600"
            onClick={deleteMultipleRecords}
          >
            <i className="mr-1 mdi mdi-delete"></i>
            Delete Multiple
          </button>
          <button
            className="flex items-center px-3 py-2 text-sm text-white rounded bg-gradient-to-r from-green-500 to-green-500 hover:from-green-600 hover:to-green-600"
            onClick={() => navigate("/imports")}
          >
            <i className="mr-1 mdi mdi-import"></i>
            Import Recipients
          </button>
          <button
            className="flex items-center px-3 py-2 text-sm text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
            onClick={toggleAddModal}
          >
            <i className="mr-1 mdi mdi-plus"></i>
            Add Recipient
          </button>
        </div>
        <h2 className="mt-3 mb-2 text-xl font-bold text-indigo-700 md:mt-0">
          All Recipients
        </h2>
        <p className="text-sm text-gray-600">
          Manage and view all registered recipients in the system
        </p>
        <div className="relative flex items-center w-full mt-6">
          <input
            type="text"
            name="search"
            placeholder="Search by name, recipient code, or email"
            ref={searchRef}
            className="w-full px-4 py-3 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            onChange={handleSearchChange}
            value={searchText}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg
              className="w-5 h-5 text-gray-400"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {searchText && (
            <button
              className="absolute right-0 flex items-center justify-center w-10 h-full text-gray-600 transition duration-150 ease-in-out hover:text-gray-900"
              onClick={handleClearSearch}
            >
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showViewModal && (
          <Modal onClose={toggleViewModal}>
            <ViewModal currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showAddModal && (
          <Modal onClose={toggleAddModal}>
            <AddModal
              setShowAddModal={setShowAddModal}
              getDataFromServer={getDataFromServer}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showUpdateModal && (
          <Modal onClose={toggleUpdateModal}>
            <UpdateModal
              setShowUpdateModal={setShowUpdateModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showClinicalModal && (
          <Modal onClose={toggleClinicalModal}>
            <ManageRecipientClinical
              setShowClinicalModal={setShowClinicalModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>

      {/* Recipients Table */}
      <div className="mt-4 overflow-hidden rounded-lg shadow-lg">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Recipients"
          checkboxSelection
          style={{ height: "60vh" }}
          onSelectionModelChange={handleSelectionChange}
          onRowClick={handleRowClick}
          getRowClassName={(params) =>
            params.id === lastViewedId ? "bg-blue-100" : ""
          }
        />
      </div>
    </div>
  );
};

export default ManageRecipientsChild;
