export const adminMenuItems = [
  { label: "Consultants", path: "/consultants" },
  { label: "Hospitals", path: "/hospitals" },
  { label: "Bloodgroup", path: "/blood" },
  { label: "Admins", path: "/admin-users" },
  { label: "Roles", path: "/roles" },
  { label: "Activity Logs", path: "/activity-logs" },
];

export const controlPanelItems = [
  { label: "Organ Recipients", path: "/recipients" },
  { label: "Bloodgroup", path: "/blood" },
];
