import React from "react";
import { adminMenuItems, controlPanelItems } from "./menuItems";
import loginImage from "../../images/no-img-9.jpg";

const MobileMenu = ({
  user,
  dropdownStates,
  toggleDropdown,
  handleHomeClick,
  handleLogout,
  navigate,
}) => {
  if (!dropdownStates.menu) return null;

  return (
    <div className="fixed inset-0 z-50 flex flex-col p-5 bg-white md:hidden">
      <div className="flex justify-end">
        <button onClick={() => toggleDropdown("menu")}>
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div className="mt-4 space-y-4">
        <a
          onClick={() => {
            handleHomeClick();
            toggleDropdown("menu");
          }}
          className="block p-2 text-gray-700 rounded cursor-pointer hover:bg-gray-100"
        >
          Home
        </a>
        {user?.role === "ADMIN" && (
          <div className="relative">
            <button
              className="flex items-center w-full p-2 space-x-2 text-left text-gray-700 rounded hover:bg-gray-100"
              onClick={() => toggleDropdown("mobilePages")}
            >
              <span>Admin Panel</span>
              <svg
                className={`w-4 h-4 ml-auto transition-transform ${
                  dropdownStates.mobilePages ? "transform rotate-180" : ""
                }`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 9l-7 7-7-7"
                ></path>
              </svg>
            </button>

            {dropdownStates.mobilePages && (
              <div className="pl-4 mt-2 space-y-2">
                {adminMenuItems.map((item, index) => (
                  <a
                    key={index}
                    onClick={() => {
                      navigate(item.path);
                      toggleDropdown("menu");
                      toggleDropdown("mobilePages");
                    }}
                    className="block p-2 text-gray-700 rounded cursor-pointer hover:bg-gray-100"
                  >
                    {item.label}
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
        <div className="relative">
          <button
            className="flex items-center w-full p-2 space-x-2 text-left text-gray-700 rounded hover:bg-gray-100"
            onClick={() => toggleDropdown("mobileManage")}
          >
            <span>Control Panel</span>
            <svg
              className={`w-4 h-4 ml-auto transition-transform ${
                dropdownStates.mobileManage ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </button>

          {dropdownStates.mobileManage && (
            <div className="pl-4 mt-2 space-y-2">
              {controlPanelItems.map((item, index) => (
                <a
                  key={index}
                  onClick={() => {
                    navigate(item.path);
                    toggleDropdown("menu");
                    toggleDropdown("mobileManage");
                  }}
                  className="block p-2 text-gray-700 rounded cursor-pointer hover:bg-gray-100"
                >
                  {item.label}
                </a>
              ))}
            </div>
          )}
        </div>
        <div className="relative">
          <div
            className="flex flex-row items-center p-2 rounded cursor-pointer hover:bg-gray-100"
            onClick={() => toggleDropdown("mobileAccount")}
          >
            <img
              src={loginImage}
              alt="User Avatar"
              className="w-8 h-8 rounded-full cursor-pointer"
            />
            <div className="ml-2">
              <div className="text-sm text-gray-700">{user?.name}</div>
              <div className="text-xs text-gray-400">{user?.user_type}</div>
            </div>
            <svg
              className={`w-4 h-4 ml-auto transition-transform ${
                dropdownStates.mobileAccount ? "transform rotate-180" : ""
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 9l-7 7-7-7"
              ></path>
            </svg>
          </div>

          {dropdownStates.mobileAccount && (
            <div className="pl-4 mt-2 space-y-2">
              <a
                onClick={() => {
                  navigate("/profile/update");
                  toggleDropdown("menu");
                  toggleDropdown("mobileAccount");
                }}
                className="block p-2 text-gray-700 rounded cursor-pointer hover:bg-gray-100"
              >
                Profile
              </a>
              <span
                onClick={() => {
                  handleLogout();
                  toggleDropdown("menu");
                  toggleDropdown("mobileAccount");
                }}
                className="block p-2 text-gray-700 rounded cursor-pointer hover:bg-gray-100"
              >
                Logout
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
