import React, { useState } from "react";
import {
  FiCalendar,
  FiClock,
  FiLink,
  FiUsers,
  FiBookmark,
  FiMessageSquare,
} from "react-icons/fi";

const MeetingModal = ({ currentRecord }) => {
  const [formData, setFormData] = useState({
    subject: "",
    meetingLink: "",
    message: "",
    qualified_pairs: "",
    meetingDate: "",
    meetingTime: "",
    attendeeEmails: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Meeting scheduled:", formData);
    // Add your schedule meeting logic here
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          Schedule Meeting
        </h2>
        <button
          type="submit"
          onClick={handleSubmit}
          className="flex items-center px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          <FiCalendar className="mr-2" />
          Schedule Meeting
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Attendee Emails
          </label>
          <div className="flex items-center px-3 py-2 border rounded-md">
            <FiUsers className="mr-2 text-gray-400" />
            <input
              type="text"
              id="attendeeEmails"
              name="attendeeEmails"
              value={formData.attendeeEmails.join(", ")}
              onChange={(e) => {
                const emails = e.target.value
                  .split(",")
                  .map((email) => email.trim());
                setFormData((prevData) => ({
                  ...prevData,
                  attendeeEmails: emails,
                }));
              }}
              placeholder="Enter email addresses separated by commas"
              className="w-full focus:outline-none"
            />
          </div>
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Subject
          </label>
          <div className="flex items-center px-3 py-2 border rounded-md">
            <FiBookmark className="mr-2 text-gray-400" />
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
              placeholder="Meeting Subject"
              className="w-full focus:outline-none"
            />
          </div>
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Meeting Link
          </label>
          <div className="flex items-center px-3 py-2 border rounded-md">
            <FiLink className="mr-2 text-gray-400" />
            <input
              type="url"
              id="meetingLink"
              name="meetingLink"
              value={formData.meetingLink}
              onChange={handleChange}
              required
              placeholder="Meeting Link"
              className="w-full focus:outline-none"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">
              Date
            </label>
            <div className="flex items-center px-3 py-2 border rounded-md">
              <FiCalendar className="mr-2 text-gray-400" />
              <input
                type="date"
                id="meetingDate"
                name="meetingDate"
                value={formData.meetingDate}
                onChange={handleChange}
                required
                className="w-full focus:outline-none"
              />
            </div>
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-700">
              Time
            </label>
            <div className="flex items-center px-3 py-2 border rounded-md">
              <FiClock className="mr-2 text-gray-400" />
              <input
                type="time"
                id="meetingTime"
                name="meetingTime"
                value={formData.meetingTime}
                onChange={handleChange}
                required
                className="w-full focus:outline-none"
              />
            </div>
          </div>
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Message
          </label>
          <div className="flex items-start px-3 py-2 border rounded-md">
            <FiMessageSquare className="mt-1 mr-2 text-gray-400" />
            <textarea
              id="message"
              name="message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              placeholder="Meeting Message"
              className="w-full resize-none focus:outline-none"
            ></textarea>
          </div>
        </div>

        <div>
          <label className="block mb-1 text-sm font-medium text-gray-700">
            Qualified Pairs
          </label>
          <div className="flex items-center px-3 py-2 border rounded-md">
            <FiUsers className="mr-2 text-gray-400" />
            <input
              type="text"
              id="qualified_pairs"
              name="qualified_pairs"
              value={formData.qualified_pairs}
              onChange={handleChange}
              placeholder="Qualified Pairs"
              className="w-full focus:outline-none"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default MeetingModal;
