import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import ViewModal from "./View/ViewModal";
import RecipientCard from "./RecipientCard";
import ManageDonorClinical from "../DonorClinical/ManageDonorClinical";
import ClinicalDataStatus from "./ClinicalDataStatus";

const ManageDonorsChild = ({ rcptRecord }) => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showClinicalModal, setShowClinicalModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const toggleClinicalModal = () => {
    setShowClinicalModal(!showClinicalModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };
  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/donors/delete/${row?._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "ManageDonorsChild", "/donors");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/donors/${rcptRecord?._id}`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data; // Access consultants array from the response data
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data?.member_title ? data?.member_title : ""} ${
              data?.donor_name
            } ${data?.last_name ? data?.last_name : ""}`,
            donor_code: data?.donor_code,
            gender_label: data?.gender?.label,
            gender: data?.gender,
            blood_group: data?.blood_group,
            bloodgroup: data?.blood_group?.label,
            donor_active_status: data?.donor_active_status,
            relationship: data?.recipient_relation?.label,
          };
        });
        setData(updatedData); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  const changeDonorActiveStatus = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/donors/change-status`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { recipient_id: row?.recipient_id, donor_id: row?._id },
    };
    axios(config)
      .then(function (response) {
        toast.success("Status Changed Successfully.");
        getDataFromServer();
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "ListModal", "/donors/change-status");
      });
  };

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleViewModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "18px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Clinical Details">
              <div
                onClick={() =>
                  navigate("/donor/clinical/add", { state: params.row })
                }
              >
                <i
                  className="mdi mdi-plus-circle"
                  style={{
                    fontSize: "16px",
                    color: "red",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Activate Donor status">
              <div onClick={() => changeDonorActiveStatus(params.row)}>
                <i
                  className="mdi mdi-checkbox-marked-circle-outline"
                  style={{
                    fontSize: "15px",
                    color: "black",
                    cursor: "pointer",
                  }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "name_f",
      headerName: "Name",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 220,
      renderCell: (params) => (
        <div className="w-full">
          <ClinicalDataStatus record={params.row} />
        </div>
      ),
    },
    {
      field: "donor_code",
      headerName: "Code",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 140,
    },
    {
      field: "gender_label",
      headerName: "Gender",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 100,
    },
    {
      field: "bloodgroup",
      headerName: "Blood Group",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 180,
    },
    {
      field: "relationship",
      headerName: "Relationship",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 180,
    },
  ];

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateModal(true);
  };

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <RecipientCard recipient={rcptRecord} />
      <div className="w-full mt-4">
        <div className="flex justify-end w-full">
          <button
            className="p-3 text-white rounded bg-gradient-to-r from-gray-500 to-gray-500 hover:from-gray-600 hover:to-gray-600"
            onClick={() => navigate(-1)}
          >
            Back
          </button>
          &nbsp;
          <button
            className="flex items-center p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
            onClick={toggleAddModal}
          >
            <i
              className="mr-2 mdi mdi-account-plus"
              style={{ fontSize: "18px" }}
            ></i>
            Add Donor
          </button>
        </div>
        <h2>Donors</h2>
        {/* View Modal */}
        <ErrorBoundaryRoutes route="/users" page="ModalView">
          {showViewModal && (
            <Modal onClose={toggleViewModal}>
              <ViewModal currentRecord={currentRecord} />
            </Modal>
          )}
        </ErrorBoundaryRoutes>
        {/* Add Modal */}
        <ErrorBoundaryRoutes route="/users" page="ModalView">
          {showAddModal && (
            <Modal onClose={toggleAddModal}>
              <AddModal
                setShowAddModal={setShowAddModal}
                getDataFromServer={getDataFromServer}
                rcptRecord={rcptRecord}
              />
            </Modal>
          )}
        </ErrorBoundaryRoutes>
        {/* Update Modal */}
        <ErrorBoundaryRoutes route="/users" page="ModalView">
          {showUpdateModal && (
            <Modal onClose={toggleUpdateModal}>
              <UpdateModal
                setShowUpdateModal={setShowUpdateModal}
                getDataFromServer={getDataFromServer}
                currentRecord={currentRecord}
              />
            </Modal>
          )}
        </ErrorBoundaryRoutes>
        {/* Clinical Modal */}
        <ErrorBoundaryRoutes route="/users" page="ModalView">
          {showClinicalModal && (
            <Modal onClose={toggleClinicalModal}>
              <ManageDonorClinical
                setShowClinicalModal={setShowClinicalModal}
                getDataFromServer={getDataFromServer}
                currentRecord={currentRecord}
              />
            </Modal>
          )}
        </ErrorBoundaryRoutes>
        {/* Donors Table */}
        <div className="mt-4">
          <DataGrid
            rows={data}
            disableSelectionOnClick
            columns={columns}
            pageSize={10}
            rowsPerPageOptions={[10]}
            displayTitle="Consultants"
            checkboxSelection
            style={{ height: "40vh" }}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageDonorsChild;
