import React from "react";

const DropdownMenu = ({ label, items, isOpen, toggleDropdown, navigate }) => {
  return (
    <div className="relative z-20">
      <button
        className="flex items-center space-x-2 text-gray-700"
        onClick={toggleDropdown}
      >
        <span>{label}</span>
        <svg
          className={`w-4 h-4 transition-transform ${
            isOpen ? "transform rotate-180" : ""
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      {isOpen && (
        <div
          onMouseLeave={toggleDropdown}
          className="absolute left-0 w-48 mt-2 bg-white border border-gray-200 rounded shadow-lg"
        >
          {items.map((item, index) => (
            <a
              key={index}
              onClick={() => {
                navigate(item.path);
                toggleDropdown();
              }}
              className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
            >
              {item.label}
            </a>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
