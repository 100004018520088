import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import jwt_decode from "jwt-decode";
import ClientCaptcha from "react-client-captcha";
import { useAtom } from "jotai";
import { gbState, globalUserStatus } from "../../components/shared/GlobalState";

import logo_image from "../../images/den-logo-raster.png";
// import backgroundImg from "../../images/donorensured-background-01.jpg";
import backgroundImg from "../../images/donorsure-background.png";

const Login = () => {
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [, setUserGlobal] = useAtom(globalUserStatus);
  const [state, setState] = useState({ captchaCode: "", valid: "" });

  const usernameRef = useRef();
  const passwordRef = useRef();
  const captchaRef = useRef();

  const setCode = (captchaCode) => {
    setState({ ...state, captchaCode });
  };

  const checkLogin = async (email, password) => {
    const credentials = { email, password };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/user/login`,
      credentials
    );
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (state.valid !== state.captchaCode) {
      toast.error("Invalid Captcha");
      captchaRef.current.focus();
      return;
    }

    if (usernameRef.current.value && passwordRef.current.value) {
      try {
        const response = await checkLogin(
          usernameRef.current.value,
          passwordRef.current.value
        );
        localStorage.setItem("token", response.data.authToken);
        const user = jwt_decode(response.data.authToken);

        setUserStatus({
          ...userStatus,
          user,
          handleLogout: () => {
            localStorage.removeItem("token");
            sessionStorage.removeItem("user-details");
            setUserStatus({ ...userStatus, user: null });
            navigate("/login", { replace: true });
          },
        });

        setUserGlobal({ ...user });

        const userRoles = ["ADMIN", "CONSULTANT"];
        if (user.role === "ADMIN") {
          navigate("/dashboard-admin", { replace: true });
        } else if (user.role === "CONSULTANT") {
          navigate("/dashboard-consultant", { replace: true });
        } else if (userRoles.includes(user.role)) {
          navigate("/dashboard-member", { replace: true });
        }

        toast.success("Logged in successfully");
      } catch (error) {
        toast.error("Invalid username or password");
      }
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen px-4 py-12 bg-gray-50 sm:px-6 lg:px-8">
      <div className="absolute inset-0 z-0">
        <img
          className="object-cover w-full h-full"
          src={backgroundImg}
          alt="Background"
        />
        {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
      </div>

      <div className="relative z-10 w-full max-w-md p-8 bg-white shadow-3xl bg-opacity-5 backdrop-filter backdrop-blur-lg rounded-xl">
        <div className="mb-8 text-center">
          <img
            src={logo_image}
            alt="Logo"
            className="w-1/3 mx-auto rounded-lg"
          />
        </div>

        <form onSubmit={handleLoginSubmit} className="space-y-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-white"
            >
              Email address
            </label>
            <input
              type="email"
              id="emailaddress"
              required
              placeholder="Enter your email"
              ref={usernameRef}
              className="w-full p-2 mt-1 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            />
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-white"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              required
              placeholder="Enter your password"
              ref={passwordRef}
              className="w-full p-2 mt-1 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
            />
          </div>

          <div className="flex flex-row items-center justify-between">
            <div className="w-1/2">
              <input
                type="text"
                placeholder="Enter Captcha"
                className="w-full p-2 text-gray-900 placeholder-gray-400 border border-gray-300 rounded-sm focus:outline-none focus:ring-2 focus:ring-sky-500"
                onChange={(e) => setState({ ...state, valid: e.target.value })}
                onKeyPress={(e) => e.key === "Enter" && handleLoginSubmit(e)}
                ref={captchaRef}
              />
            </div>
            <div className="w-1/2 ml-3">
              <ClientCaptcha
                captchaCode={setCode}
                width={150}
                className="w-full rounded-lg"
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 text-lg font-semibold text-white transition duration-300 rounded-sm bg-gradient-to-r from-sky-500 to-navy-500 hover:from-navy-600 hover:to-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
          >
            Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
