// src/components/TopMenu.jsx
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";
import logo_image from "../../images/donor-ensured-logo.png";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import useDropdownState from "./useDropdownState";
import { FaBell, FaCalendarAlt } from "react-icons/fa";

const TopMenu = ({ menuState, setMenuState }) => {
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useAtom(gbState);
  const [{ user }] = useAtom(gbState);
  const { dropdownStates, toggleDropdown } = useDropdownState();
  const [hasNotification, setHasNotification] = React.useState(true);
  const [hasMeeting, setHasMeeting] = React.useState(true);

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("user-details");
    setUserStatus({ ...userStatus, user: null });
    navigate("/login", { replace: true });
  };

  const handleHomeClick = () => {
    if (user?.role === "ADMIN") {
      navigate("/dashboard-admin");
    } else {
      navigate("/dashboard-consultant");
    }
  };

  return (
    <div className="bg-white shadow-md">
      <nav className="relative flex items-center justify-between p-5 m-0 bg-white shadow-md">
        {/* Logo and brand name */}
        <div className="flex flex-row items-center font-bold">
          <img src={logo_image} alt="logo" className="w-10 ml-2" />
          <span className="ml-2 text-gray-700">DonorSure</span>
        </div>

        {/* Mobile menu button */}
        <div className="flex items-center md:hidden">
          <button onClick={() => toggleDropdown("menu")}>
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              ></path>
            </svg>
          </button>
        </div>

        <DesktopMenu
          user={user}
          dropdownStates={dropdownStates}
          toggleDropdown={toggleDropdown}
          handleHomeClick={handleHomeClick}
          handleLogout={handleLogout}
          navigate={navigate}
          hasNotification={hasNotification}
          hasMeeting={hasMeeting}
        />

        <MobileMenu
          user={user}
          dropdownStates={dropdownStates}
          toggleDropdown={toggleDropdown}
          handleHomeClick={handleHomeClick}
          handleLogout={handleLogout}
          navigate={navigate}
        />
      </nav>
    </div>
  );
};

export default TopMenu;
