import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import ViewModal from "./View/ViewModal";

const ManageAdminsChild = () => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [searchInput, setSearchInput] = useState("");

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/user/delete/${row?._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "ManageConsultantsChild", "/consultants");
      });
  };

  const getDataFromServer = () => {
    console.log("Fetching data with search term:", searchInput);
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/user/`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data.users;
        const filteredData = oldData.filter(
          (data) =>
            data.first_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            data.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
            data.email.toLowerCase().includes(searchInput.toLowerCase())
        );
        const updatedData = filteredData.map((data) => {
          return {
            ...data,
            id: data._id,
            name: `${data?.member_title} ${data?.first_name} ${data?.last_name}`,
            email: data?.email,
            mobile: data?.mobile,
            organization: data?.organization,
          };
        });
        setData(updatedData);
        console.log("Filtered data:", updatedData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, [searchInput]);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  setCurrentRecord(params.row);
                  toggleViewModal();
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 190,
      renderCell: (params) => (
        <div className="flex items-center">
          <div
            className={`w-2 h-2 rounded-full mr-2 ${
              params.row.account_status ? "bg-green-500" : "bg-red-500"
            }`}
          ></div>
          {params.value}
        </div>
      ),
    },

    {
      field: "organization",
      headerName: "Organization",
      width: 200,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 180,
    },
    {
      field: "email",
      headerName: "Email",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 180,
    },
  ];

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateModal(true);
  };

  return (
    <div
      className="flex flex-col w-full h-full"
      style={{ wordBreak: "break-word" }}
    >
      <div className="flex justify-end w-full mb-4">
        <button
          className="flex items-center p-3 text-white rounded bg-gradient-to-r from-blue-500 to-blue-500 hover:from-blue-600 hover:to-blue-600"
          onClick={toggleAddModal}
        >
          <i
            className="mr-2 mdi mdi-account-plus"
            style={{ fontSize: "18px" }}
          ></i>
          Add Admin
        </button>
      </div>
      <h2 className="mb-4">Administrators</h2>
      <div style={{ position: "relative", width: "100%" }}>
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          style={{
            width: "100%",
            padding: "10px 40px 10px 30px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        <SearchIcon
          style={{
            position: "absolute",
            left: "10px",
            top: "50%",
            transform: "translateY(-50%)",
          }}
        />
        {searchInput && (
          <ClearIcon
            onClick={() => setSearchInput("")}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              cursor: "pointer",
              transform: "translateY(-50%)",
            }}
          />
        )}
      </div>

      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showViewModal && (
          <Modal onClose={toggleViewModal}>
            <ViewModal currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showAddModal && (
          <Modal onClose={toggleAddModal}>
            <AddModal
              setShowAddModal={setShowAddModal}
              getDataFromServer={getDataFromServer}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showUpdateModal && (
          <Modal onClose={toggleUpdateModal}>
            <UpdateModal
              setShowUpdateModal={setShowUpdateModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Consultants"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default ManageAdminsChild;
