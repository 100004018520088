import React from "react";

const CardBackground = ({ children }) => {
  return (
    <div
      className="flex flex-row p-1 m-2 space-y-0 bg-white rounded-md shadow md:p-6 md:flex-row md:space-y-0 md:ml-4 md:mr-4 md:mb-4"
      style={{ wordBreak: "break-word" }}
    >
      {children}
    </div>
  );
};

export default CardBackground;
