import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import { DataGrid } from "@material-ui/data-grid";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import {
  FaEye,
  FaEdit,
  FaTrash,
  FaPlus,
  FaTint,
  FaSearch,
} from "react-icons/fa";

import Modal from "../../components/Modal/Modal";
import ErrorBoundaryRoutes from "../../components/shared/ErrorBoundaryRoutes";

//Global state Access
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

import AddModal from "./Add/AddModal";
import UpdateModal from "./Update/UpdateModal";
import ViewModal from "./View/ViewModal";

const ManageBloodgroupChild = () => {
  let navigate = useNavigate();
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({});
  const [data, setData] = useState([]);
  const [{ errorLogApi, placeHolderText, user }] = useAtom(gbState);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const toggleViewModal = () => {
    setShowViewModal(!showViewModal);
  };
  const toggleAddModal = () => {
    setShowAddModal(!showAddModal);
  };
  const toggleUpdateModal = () => {
    setShowUpdateModal(!showUpdateModal);
  };

  const handleDelete = (row) => {
    confirmAlert({
      title: "",
      message: "Are you sure to delete ?",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteRecord(row),
        },
        {
          label: "No",
          //onClick: () => alert('Click No')
        },
      ],
    });
  };

  const deleteRecord = (row) => {
    let userToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${userToken}` },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}/bloodgroup/delete/${row?._id}`,
        config
      )
      .then((response) => {
        //alert(JSON.stringify(response));
        getDataFromServer();
      })
      .catch((error) => {
        toast.error(
          error?.response?.data
            ? error?.response?.data?.error
            : "An error occurred"
        );
        errorLogApi(error, "ManageBloodgroupChild", "/bloodgroup");
      });
  };

  const getDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/bloodgroup/`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data; // Access consultants array from the response data
        console.log(oldData);
        const updatedData = oldData.map((data) => {
          return {
            ...data,
            id: data._id,
            bloodgroup: data?.bloodgroup,
          };
        });
        setData(updatedData); // Assuming setData is a useState hook function
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getDataFromServer();
  }, []);

  useEffect(() => {
    setFilteredData(
      data.filter((item) =>
        item.bloodgroup.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [data, searchTerm]);

  const columns = [
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      hideable: false,
      disableColumnMenu: true,
      width: 80,
      align: "center",
      renderCell: (params) => {
        return (
          <div className="flex flex-row">
            <Tooltip title="View  details">
              <div
                onClick={() => {
                  toggleViewModal();
                  setCurrentRecord(params.row);
                }}
              >
                <i
                  className="cursor-pointer mdi mdi-eye-circle-outline"
                  style={{ fontSize: "15px", color: "black" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Edit">
              <div onClick={() => handleEditUser(params.row)}>
                <i
                  className="mdi mdi-square-edit-outline"
                  style={{ fontSize: "15px", color: "grey", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
            <Tooltip title="Delete">
              <div
                onClick={() => {
                  handleDelete(params.row);
                }}
              >
                <i
                  className="mdi mdi-trash-can-outline"
                  style={{ fontSize: "15px", color: "red", cursor: "pointer" }}
                ></i>
              </div>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "bloodgroup",
      headerName: "bloodgroup",
      sortable: true,
      hideable: false,
      disableColumnMenu: true,
      width: 180,
    },
  ];

  const handleEditUser = (row) => {
    setCurrentRecord(row);
    setShowUpdateModal(true);
  };

  return (
    <div className="w-full" style={{ wordBreak: "break-word" }}>
      <div className="flex items-center justify-between mb-6">
        <h1 className="flex items-center text-3xl font-bold text-gray-800">
          <FaTint className="mr-2 text-red-600" />
          Manage Blood Groups
        </h1>
        <button
          className="flex items-center px-4 py-2 text-white transition duration-300 ease-in-out bg-blue-600 rounded-md hover:bg-blue-700"
          onClick={toggleAddModal}
        >
          <FaPlus className="mr-2" />
          Add Blood Group
        </button>
      </div>

      {/* Add search bar */}
      <div className="w-full mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Search blood groups..."
            className="w-full px-3 py-2 pl-10 border border-gray-300 rounded-md"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FaSearch className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2" />
        </div>
      </div>

      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showViewModal && (
          <Modal onClose={toggleViewModal}>
            <ViewModal currentRecord={currentRecord} />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showAddModal && (
          <Modal onClose={toggleAddModal}>
            <AddModal
              setShowAddModal={setShowAddModal}
              getDataFromServer={getDataFromServer}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      <ErrorBoundaryRoutes route="/users" page="ModalView">
        {showUpdateModal && (
          <Modal onClose={toggleUpdateModal}>
            <UpdateModal
              setShowUpdateModal={setShowUpdateModal}
              getDataFromServer={getDataFromServer}
              currentRecord={currentRecord}
            />
          </Modal>
        )}
      </ErrorBoundaryRoutes>
      {/* {JSON.stringify(data)} */}
      <div className="mt-4">
        <DataGrid
          rows={filteredData}
          disableSelectionOnClick
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          displayTitle="Bloodgroups"
          checkboxSelection
          style={{ height: "40vh" }}
        />
      </div>
    </div>
  );
};

export default ManageBloodgroupChild;
