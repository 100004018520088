import React from "react";
import { Bell, MessageCircle, FileText, Calendar, X } from "lucide-react";

const NotificationModal = () => {
  const notifications = [
    {
      id: 1,
      message: "New message from Dr. Nirvaan",
      icon: MessageCircle,
      time: "5m ago",
    },
    {
      id: 2,
      message: "SAB report is ready for review",
      icon: FileText,
      time: "1h ago",
    },
    {
      id: 3,
      message: "Donor:DNR-101-2912 meeting at 2 PM",
      icon: Calendar,
      time: "2h ago",
    },
    {
      id: 4,
      message: "Reminder: Submit Clinical detaile by EOD",
      icon: Calendar,
      time: "3h ago",
    },
  ];

  return (
    <div className="p-6 mx-auto ">
      <div className="flex items-center justify-between mb-6">
        <h2 className="flex items-center text-2xl font-bold text-gray-800">
          <Bell className="mr-2 text-blue-500" />
          Notifications
        </h2>
      </div>
      {notifications.length > 0 ? (
        <ul className="space-y-4">
          {notifications.map((notification) => (
            <li
              key={notification.id}
              className="flex items-start p-3 transition-colors duration-200 rounded-lg bg-gray-50 hover:bg-gray-100"
            >
              <notification.icon
                className="flex-shrink-0 mt-1 mr-3 text-blue-500"
                size={20}
              />
              <div className="flex-grow">
                <p className="text-gray-800">{notification.message}</p>
                <span className="text-sm text-gray-500">
                  {notification.time}
                </span>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="py-4 text-center text-gray-600">No new notifications</p>
      )}
      <button className="w-full px-4 py-2 mt-6 text-white transition-colors duration-200 bg-blue-500 rounded-lg hover:bg-blue-600">
        Mark all as read
      </button>
    </div>
  );
};

export default NotificationModal;
