import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DropdownMenu from "./DropdownMenu";
import { adminMenuItems, controlPanelItems } from "./menuItems";
import loginImage from "../../images/no-img-9.jpg";
import { FaBell, FaCalendarAlt, FaEnvelope } from "react-icons/fa";

import Modal from "../Modal/Modal";
import NotificationModal from "./NotificationModal";
import CalenderModal from "./CalenderModal";

const DesktopMenu = ({
  user,
  dropdownStates,
  toggleDropdown,
  handleHomeClick,
  handleLogout,
  navigate,
  hasNotification,
  hasMeeting,
  hasNewMail, // Add this prop
}) => {
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [showCalenderModal, setShowCalenderModal] = useState(false);

  const toggleNotificationModal = () => {
    setShowNotificationModal(!showNotificationModal);
  };

  const toggleCalenderModal = () => {
    setShowCalenderModal(!showCalenderModal);
  };

  return (
    <div className="hidden md:flex md:flex-row md:items-center md:space-x-6">
      {showNotificationModal && (
        <Modal onClose={toggleNotificationModal}>
          <NotificationModal />
        </Modal>
      )}

      {showCalenderModal && (
        <Modal onClose={toggleCalenderModal}>
          <CalenderModal />
        </Modal>
      )}

      {/* Home link */}
      <a
        onClick={handleHomeClick}
        className="pl-1 pr-1 text-gray-700 cursor-pointer hover:bg-gray-100"
      >
        Home
      </a>

      {/* Control Panel dropdown */}
      <DropdownMenu
        label="Control Panel"
        items={controlPanelItems}
        isOpen={dropdownStates.manage}
        toggleDropdown={() => toggleDropdown("manage")}
        navigate={navigate}
      />

      {/* Admin Panel dropdown (if user is admin) */}
      {user?.role === "ADMIN" && (
        <DropdownMenu
          label="Admin Panel"
          items={adminMenuItems}
          isOpen={dropdownStates.pages}
          toggleDropdown={() => toggleDropdown("pages")}
          navigate={navigate}
        />
      )}

      {/* Notification Icon */}
      {/* <div className="relative">
        <FaBell
          className="text-xl text-gray-600 cursor-pointer"
          onClick={toggleNotificationModal}
        />
        {hasNotification && (
          <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></div>
        )}
      </div> */}

      {/* Meetings Icon */}
      {/* <div className="relative">
        <FaCalendarAlt
          className="text-xl text-gray-600 cursor-pointer"
          onClick={toggleCalenderModal}
        />
        {hasMeeting && (
          <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></div>
        )}
      </div> */}

      {/* Mail Icon */}
      {/* <div className="relative" onClick={() => navigate("/mailbox")}>
        <FaEnvelope className="text-xl text-gray-600 cursor-pointer" />
        {hasNewMail && (
          <div className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></div>
        )}
      </div> */}

      {/* Mobile Icons */}
      <div className="flex space-x-4 sm:hidden">
        <FaBell
          className="text-xl text-gray-600 cursor-pointer"
          onClick={toggleNotificationModal}
        />
        <FaCalendarAlt className="text-xl text-gray-600 cursor-pointer" />
        <FaEnvelope className="text-xl text-gray-600 cursor-pointer" />
      </div>

      {/* User account dropdown */}
      <div className="relative">
        <div
          className="flex flex-row items-center cursor-pointer"
          onClick={() => toggleDropdown("account")}
        >
          <img
            src={loginImage}
            alt="User Avatar"
            className="w-8 h-8 rounded-full cursor-pointer"
          />
          <div className="ml-2">
            <div className="text-xs text-gray-700">{user?.name}</div>
            <div className="text-gray-400 text-[0.5rem]">{user?.user_type}</div>
          </div>
        </div>

        {dropdownStates.account && (
          <div
            onMouseLeave={() => toggleDropdown("account")}
            className="absolute right-0 w-48 mt-2 bg-white border border-gray-200 rounded shadow-lg"
          >
            <a
              onClick={() => {
                navigate("/profile/update");
                toggleDropdown("account");
              }}
              className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
            >
              Profile
            </a>
            <span
              onClick={() => {
                handleLogout();
                toggleDropdown("account");
              }}
              className="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
            >
              Logout
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopMenu;
