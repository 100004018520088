import React, { useState } from "react";
import TopMenu from "../TopMenu/TopMenu";
import { useAtom } from "jotai";
import { gbState } from "../../components/shared/GlobalState";

// import CardBackground from "../../pages/Dashboard/CardBackground";

const MainTemplate = ({ children }) => {
  const [menuState, setMenuState] = useState(true);

  return (
    <div className="flex flex-col h-screen">
      <div className="fixed top-0 left-0 right-0 z-10">
        <TopMenu menuState={menuState} setMenuState={setMenuState} />
      </div>
      <div className="flex-grow pt-16 mt-5 overflow-y-auto bg-gray-100">
        {children}
      </div>
    </div>
  );
};

export default MainTemplate;
