import React, { useState } from "react";
import {
  FaUser,
  FaTint,
  FaBirthdayCake,
  FaMapMarkerAlt,
  FaPhone,
  FaIdCard,
  FaUserMd,
  FaUserFriends,
  FaComments,
  FaUserInjured,
  FaHandHoldingHeart,
} from "react-icons/fa";

import GenderIcon from "./GenderIcon";

const ChevronIcon = ({ isOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={`h-5 w-5 transition-transform duration-300 ${
      isOpen ? "rotate-180" : ""
    }`}
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M19 9l-7 7-7-7"
    />
  </svg>
);

const PersonCard = ({
  title,
  data,
  isOpen,
  toggleOpen,
  cardColor,
  person_type,
}) => {
  const fullName = `${data?.member_title} ${
    data?.recipient_name || data?.donor_name
  } ${data?.last_name}`;

  return (
    <div className="w-full p-2 md:w-1/2">
      <div
        className={`bg-white rounded-lg shadow-md overflow-hidden ${
          isOpen ? "shadow-lg" : ""
        }`}
      >
        <div
          className={`flex items-center justify-between p-4 text-white cursor-pointer ${cardColor}`}
          onClick={toggleOpen}
        >
          <div
            className={`p-3 rounded-full ${
              person_type === "R" ? "bg-white" : "bg-white"
            } mr-4`}
          >
            {person_type === "R" ? (
              <FaUserInjured className="w-5 h-5 text-indigo-500" />
            ) : (
              <FaHandHoldingHeart className="w-5 h-5 text-green-500" />
            )}
          </div>
          <h2 className="text-xl font-semibold">{title}</h2>
          <ChevronIcon isOpen={isOpen} />
        </div>
        <div className="p-4">
          <div className="flex items-center mb-4">
            <h3 className="text-lg font-semibold text-gray-800">{fullName}</h3>
          </div>
          <div className="flex flex-wrap mb-4 -mx-2">
            <InfoCard
              icon={<GenderIcon gender={data?.gender?.label} />}
              label="Gender"
              value={data?.gender?.label}
              bgColor="bg-blue-100"
              textColor="text-blue-800"
            />
            <InfoCard
              icon={<FaTint />}
              label="Blood Group"
              value={data?.blood_group?.label}
              bgColor="bg-red-100"
              textColor="text-red-800"
            />
          </div>
          <div
            className={`overflow-hidden transition-all duration-300 ${
              isOpen ? "max-h-screen" : "max-h-0"
            }`}
          >
            <div className="pt-3 space-y-3 border-t">
              <InfoRow
                icon={<FaBirthdayCake />}
                label="Date of Birth"
                value={new Date(data?.dob).toLocaleDateString()}
              />
              <InfoRow
                icon={<FaMapMarkerAlt />}
                label="Address"
                value={data?.address}
              />
              <InfoRow
                icon={<FaPhone />}
                label="Primary Mobile"
                value={data?.primary_mobile}
              />
              {data?.recipient_code && (
                <InfoRow
                  icon={<FaIdCard />}
                  label="Recipient Code"
                  value={data?.recipient_code}
                />
              )}
              {data?.donor_code && (
                <InfoRow
                  icon={<FaIdCard />}
                  label="Donor Code"
                  value={data?.donor_code}
                />
              )}
              {data?.treating_doctor && (
                <InfoRow
                  icon={<FaUserMd />}
                  label="Treating Doctor"
                  value={data?.treating_doctor}
                />
              )}
              {data?.recipient_relation && (
                <InfoRow
                  icon={<FaUserFriends />}
                  label="Relationship to Recipient"
                  value={data?.recipient_relation?.label}
                />
              )}
              <InfoRow
                icon={<FaComments />}
                label="Comments"
                value={data?.comments}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoCard = ({ icon, label, value, bgColor, textColor }) => (
  <div className="w-1/2 px-2 mb-2">
    <div className={`p-2 rounded-lg ${bgColor} flex items-center`}>
      <span className={`mr-2 ${textColor}`}>{icon}</span>
      <div>
        <span className={`block text-xs font-medium ${textColor}`}>
          {label}
        </span>
        <span className={`text-sm font-bold ${textColor}`}>{value}</span>
      </div>
    </div>
  </div>
);

const InfoRow = ({ icon, label, value }) => (
  <div className="flex flex-col items-center sm:flex-row sm:justify-between">
    <span className="flex items-center text-sm font-medium text-gray-600">
      {icon && <span className="mr-2">{icon}</span>}
      {label}:
    </span>
    <span className="text-sm text-gray-800">{value}</span>
  </div>
);

const RecipientDonorCards = ({ recipient, donor }) => {
  const [isRecipientOpen, setIsRecipientOpen] = useState(false);
  const [isDonorOpen, setIsDonorOpen] = useState(false);

  return (
    <div className="flex flex-wrap -mx-2">
      <PersonCard
        title="Primary Recipient"
        data={recipient}
        isOpen={isRecipientOpen}
        toggleOpen={() => setIsRecipientOpen(!isRecipientOpen)}
        cardColor="bg-gradient-to-r from-blue-500 to-blue-600"
        person_type="R"
      />
      <PersonCard
        title="Primary Donor"
        data={donor}
        isOpen={isDonorOpen}
        toggleOpen={() => setIsDonorOpen(!isDonorOpen)}
        cardColor="bg-gradient-to-r from-green-500 to-green-600"
        person_type="D"
      />
    </div>
  );
};

export default RecipientDonorCards;
