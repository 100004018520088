import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  FaUserInjured,
  FaHandHoldingHeart,
  FaTint,
  FaUserFriends,
  FaChartLine,
  FaHeartbeat,
} from "react-icons/fa";
import { FaKitMedical } from "react-icons/fa6";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DashboardConsultantChild = () => {
  let navigate = useNavigate();
  const [recipients, setRecipients] = useState([]);
  const [donors, setDonors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recipientsClinical, setRecipientClinicals] = useState([]);
  const [donorsClinical, setDonorClinicals] = useState([]);
  const [clinicals, setClinicals] = useState([]);

  const getClinicalDataFromServer = () => {
    let userToken = localStorage.getItem("token");
    let urlLink = `${process.env.REACT_APP_API_URL}/clinical`;
    const config = {
      method: "GET",
      url: urlLink,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    };

    axios(config)
      .then(function (response) {
        const oldData = response.data;
        setClinicals(oldData);
      })
      .catch(function (err) {
        console.log("error api call ", err?.response?.data);
      });
  };

  useEffect(() => {
    getClinicalDataFromServer();
  }, []);

  useEffect(() => {
    if (clinicals?.length) {
      setRecipientClinicals(clinicals.filter((item) => item.donor_id === null));
      setDonorClinicals(clinicals.filter((item) => item.donor_id !== null));
    }
  }, [clinicals]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [recipientsResponse, donorsResponse] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/recipients`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
          axios.get(`${process.env.REACT_APP_API_URL}/donors`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }),
        ]);

        setRecipients(recipientsResponse.data);
        setDonors(donorsResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const bloodGroupData = donors.reduce((acc, donor) => {
    const bloodGroup = donor.blood_group?.label || "Unknown";
    acc[bloodGroup] = (acc[bloodGroup] || 0) + 1;
    return acc;
  }, {});

  const ageData = donors.reduce((acc, donor) => {
    const age = donor.age || 0;
    const ageGroup = Math.floor(age / 10) * 10;
    acc[ageGroup] = (acc[ageGroup] || 0) + 1;
    return acc;
  }, {});

  const bloodGroupChartData = {
    labels: Object.keys(bloodGroupData),
    datasets: [
      {
        label: "Donors by Blood Group",
        data: Object.values(bloodGroupData),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const ageChartData = {
    labels: Object.keys(ageData)
      .sort((a, b) => a - b)
      .map((age) => `${age}-${parseInt(age) + 9}`),
    datasets: [
      {
        label: "Donors by Age Group",
        data: Object.values(ageData),
        fill: false,
        borderColor: "rgb(255, 99, 132)",
        tension: 0.1,
      },
    ],
  };

  const prepareBloodPressureData = (clinicalData) => {
    return {
      labels: clinicalData.map((data) => data.date_of_entry.split("T")[0]),
      datasets: [
        {
          label: "Systolic",
          data: clinicalData.map((data) => data.blood_pressure.systolic),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Diastolic",
          data: clinicalData.map((data) => data.blood_pressure.diastolic),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };
  };

  const prepareCBCData = (clinicalData) => {
    return {
      labels: clinicalData.map((data) => data.date_of_entry.split("T")[0]),
      datasets: [
        {
          label: "WBC",
          data: clinicalData.map((data) => data.cbc.wbc),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Platelets",
          data: clinicalData.map((data) => data.cbc.platelets),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "Hemoglobin",
          data: clinicalData.map((data) => data.cbc.hemoglobin),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    };
  };

  const prepareRenalProfileData = (clinicalData) => {
    return {
      labels: clinicalData.map((data) => data.date_of_entry.split("T")[0]),
      datasets: [
        {
          label: "Creatinine",
          data: clinicalData.map((data) => data.renal_profile.creatinine),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: "Glucose",
          data: clinicalData.map((data) => data.renal_profile.glucose),
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
        {
          label: "HbA1c",
          data: clinicalData.map((data) => data.renal_profile.HbA1c),
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          font: {
            family: "'Roboto', sans-serif",
            size: 12,
          },
          color: "#4a5568",
        },
      },
      title: {
        display: true,
        text: "Clinical Data Over Time",
      },
    },
    scales: {
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          font: {
            family: "'Roboto', sans-serif",
            size: 10,
          },
          color: "#4a5568",
        },
      },
      y: {
        grid: {
          color: "rgba(0, 0, 0, 0.1)",
        },
        ticks: {
          font: {
            family: "'Roboto', sans-serif",
            size: 10,
          },
          color: "#4a5568",
        },
      },
    },
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="w-16 h-16 border-b-2 border-indigo-600 rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen p-4 md:p-6 lg:p-8">
      <h1 className="mb-6 text-2xl font-bold text-center text-gray-800 sm:text-3xl lg:text-4xl">
        Organ Transplant Dashboard
      </h1>
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <div className="p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-indigo-700 sm:text-xl">
            Total Recipients
          </h2>
          <div className="flex items-center">
            <FaUserInjured className="w-10 h-10 mr-4 text-indigo-500 sm:w-12 sm:h-12" />
            <span className="text-3xl font-bold text-gray-800 sm:text-4xl">
              {recipients.length}
            </span>
          </div>
        </div>
        <div className="p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-green-700 sm:text-xl">
            Total Donors
          </h2>
          <div className="flex items-center">
            <FaHandHoldingHeart className="w-10 h-10 mr-4 text-green-500 sm:w-12 sm:h-12" />
            <span className="text-3xl font-bold text-gray-800 sm:text-4xl">
              {donors.length}
            </span>
          </div>
        </div>
        <div className="col-span-1 p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm sm:col-span-2 lg:col-span-1 hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-red-700 sm:text-xl">
            <FaTint className="inline-block mr-2 text-red-500" />
            Blood Group Distribution
          </h2>
          <div className="h-64">
            <Bar data={bloodGroupChartData} options={chartOptions} />
          </div>
        </div>
        <div className="col-span-1 p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm sm:col-span-2 lg:col-span-1 hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-blue-700 sm:text-xl">
            <FaUserFriends className="inline-block mr-2 text-blue-500" />
            Donor Age Distribution
          </h2>
          <div className="h-64">
            <Line data={ageChartData} options={chartOptions} />
          </div>
        </div>
        <div className="col-span-1 p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm sm:col-span-2 lg:col-span-1 xl:col-span-2 hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-purple-700 sm:text-xl">
            <FaHeartbeat className="inline-block mr-2 text-purple-500" />
            Blood Pressure Trends
          </h2>
          <div className="h-64">
            <Line
              data={prepareBloodPressureData(recipientsClinical)}
              options={chartOptions}
            />
          </div>
        </div>
        <div className="col-span-1 p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm sm:col-span-2 lg:col-span-1 xl:col-span-2 hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-orange-700 sm:text-xl">
            <FaChartLine className="inline-block mr-2 text-orange-500" />
            CBC Trends
          </h2>
          <div className="h-64">
            <Line
              data={prepareCBCData(recipientsClinical)}
              options={chartOptions}
            />
          </div>
        </div>
        <div className="col-span-1 p-6 transition duration-300 ease-in-out bg-white border border-gray-200 rounded-lg shadow-sm sm:col-span-2 lg:col-span-3 xl:col-span-4 hover:shadow-md">
          <h2 className="mb-4 text-lg font-semibold text-teal-700 sm:text-xl">
            <FaKitMedical className="inline-block mr-2 text-teal-500" />
            Renal Profile Trends
          </h2>
          <div className="h-64">
            <Line
              data={prepareRenalProfileData(recipientsClinical)}
              options={chartOptions}
            />
          </div>
        </div>
      </div>
      <div className="mt-8 text-center">
        <button
          onClick={() => navigate("/recipients")}
          className="px-6 py-3 text-base font-bold text-white transition duration-300 ease-in-out bg-indigo-600 rounded-full shadow-lg hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
        >
          Find Matching Donor
        </button>
      </div>
    </div>
  );
};

export default DashboardConsultantChild;
