import React, { useEffect, useState } from "react";
import {
  FiSend,
  FiUser,
  FiUserCheck,
  FiMessageSquare,
  FiBookmark,
} from "react-icons/fi";

import MatchPairOverallScore from "./MatchPairOverallScore";

const MessageModal = ({ currentRecord }) => {
  const [content, setContent] = useState(`Dear Consultant,

I hope this message finds you well. I am writing to request additional clinical information for our secondary donor, ${currentRecord?.secondaryDonor?.donor_name} ${currentRecord?.secondaryDonor?.last_name}.

Specifically, we are looking for:
1. Recent lab results
2. Any updates on medical history
3. Current medication list
4. Any other relevant clinical information

This information will be crucial for our ongoing assessment and matching process. Your prompt attention to this matter would be greatly appreciated.

Thank you for your cooperation.

Best regards,
[Your Name]`);
  const [subject, setSubject] = useState("");
  const sender = currentRecord?.primaryRecipient?.consultant_id;
  const receiver = currentRecord?.secondaryRecipient?.consultant_id;

  const handleSendMessage = () => {
    const messageData = {
      sender,
      receiver,
      qualified_pairs: [], // Add logic to populate this
      subject,
      content,
    };
    console.log("Message sent:", messageData);
    // Add your send message logic here
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-md">
      {sender === receiver ? (
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-semibold text-red-600">
            This is your DB local match.
          </h2>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-2xl font-semibold text-gray-800">
              Send Message
            </h2>
            <button
              onClick={handleSendMessage}
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <FiSend className="inline-block mr-2" />
              Send
            </button>
          </div>

          <div className="space-y-4">
            {/* <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                Sender ID
              </label>
              <div className="flex items-center px-3 py-2 border rounded-md bg-gray-50">
                <FiUser className="mr-2 text-gray-400" />
                <input
                  type="text"
                  value={sender}
                  readOnly
                  className="w-full text-gray-700 bg-transparent focus:outline-none"
                />
              </div>
            </div> */}

            <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                Receiver ID
              </label>
              <div className="flex items-center px-3 py-2 border rounded-md bg-gray-50">
                <FiUserCheck className="mr-2 text-gray-400" />
                <input
                  type="text"
                  value={receiver}
                  readOnly
                  className="w-full text-gray-700 bg-transparent focus:outline-none"
                />
              </div>
            </div>

            <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                Subject
              </label>
              <div className="flex items-center px-3 py-2 border rounded-md">
                <FiBookmark className="mr-2 text-gray-400" />
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  placeholder="Enter message subject"
                  className="w-full focus:outline-none"
                />
              </div>
            </div>

            <div>
              <label className="block mb-1 text-sm font-medium text-gray-700">
                Message Content
              </label>
              <div className="flex items-start px-3 py-2 border rounded-md">
                <FiMessageSquare className="mt-1 mr-2 text-gray-400" />
                <textarea
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder={`Dear Consultant,

I hope this message finds you well. I am writing to request additional clinical information for our secondary donor, ${currentRecord?.secondaryDonor?.donor_name} ${currentRecord?.secondaryDonor?.last_name}.

Specifically, we are looking for:
1. Recent lab results
2. Any updates on medical history
3. Current medication list
4. Any other relevant clinical information

This information will be crucial for our ongoing assessment and matching process. Your prompt attention to this matter would be greatly appreciated.

Thank you for your cooperation.

Best regards,
[Your Name]`}
                  rows="10"
                  className="w-full resize-none focus:outline-none"
                />
              </div>
            </div>
            <div className="">
              <MatchPairOverallScore pair={currentRecord} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default MessageModal;
