import { useState, useCallback } from "react";

const useDropdownState = () => {
  const [dropdownStates, setDropdownStates] = useState({
    pages: false,
    account: false,
    manage: false,
    menu: false,
    mobilePages: false,
    mobileManage: false,
    mobileAccount: false,
  });

  const toggleDropdown = useCallback((dropdown) => {
    setDropdownStates((prev) => ({ ...prev, [dropdown]: !prev[dropdown] }));
  }, []);

  return { dropdownStates, toggleDropdown };
};

export default useDropdownState;
