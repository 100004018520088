import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import axios from "axios";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import { useAtom } from "jotai";
import { gbState } from "../../../components/shared/GlobalState";

import GeographicalComponent from "../../../components/GeographicalComponent/GeographicalComponent";
import {
  memberTitleOptions,
  genderOptions,
} from "../../../components/Data/dropdownoptions";

const AddModal = ({ getDataFromServer, setShowAddModal }) => {
  let navigate = useNavigate();
  const [bloodgroupOptions, setBloodgroupOptions] = useState();
  // Error Logging Service & Sanitize Input
  const [{ errorLogApi, placeHolderText }] = useAtom(gbState);
  const [formData, setFormData] = useState({
    member_title: "",
    recipient_name: "",
    last_name: "",
    dob: "",
    gender: "",
    treating_doctor: "",
    registration_number: "",
    blood_group: "",
    country: "",
    state: "",
    city: "",
    address: "",
    primary_mobile: "",
    secondary_mobile: "",
    whatsapp_number: "",
    comments: "",
  });

  const style = {
    control: (base) => ({
      ...base,
      border: "1px solid lightgray",
      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  useEffect(() => {
    const getDataFromServer = () => {
      let userToken = localStorage.getItem("token");
      let urlLink = `${process.env.REACT_APP_API_URL}/bloodgroup/`;
      const config = {
        method: "GET",
        url: urlLink,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      };

      axios(config)
        .then(function (response) {
          const oldData = response.data; // Access consultants array from the response data
          //   console.log(oldData);
          const updatedData = oldData.map((data) => {
            return {
              value: data?.bloodgroup,
              label: data?.bloodgroup,
            };
          });
          setBloodgroupOptions(updatedData); // Assuming setData is a useState hook function
        })
        .catch(function (err) {
          console.log("error api call ", err?.response?.data);
        });
    };
    getDataFromServer();
  }, []);

  const handleChange = ({ currentTarget: input }) => {
    setFormData({
      ...formData,
      [input.name]: input.value,
    });
  };

  const handleDateChange = (date, dateString) => {
    setFormData({
      ...formData,
      dob: dateString,
    });
  };

  const dropDownChange = (selected, dropdown) => {
    setFormData({
      ...formData,
      [dropdown.name]: selected.value,
    });
  };

  const handleSelectChange = (selectedOption) => {
    setFormData({
      ...formData,
      member_title: selectedOption.value,
    });
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();

    // Add validation for mandatory fields
    const mandatoryFields = [
      "member_title",
      "recipient_name",
      "last_name",
      "dob",
      "gender",
      "blood_group",
      "primary_mobile",
    ];
    const missingFields = mandatoryFields.filter((field) => !formData[field]);

    if (missingFields.length > 0) {
      toast.error(
        `Please fill in all mandatory fields: ${missingFields.join(", ")}`
      );
      return;
    }

    let userToken = localStorage.getItem("token");

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/recipients/add`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
      data: { ...formData },
    };
    axios(config)
      .then(function (response) {
        toast.success("Recipient Created Successfully.");
        getDataFromServer();
        setShowAddModal(false);
      })
      .catch(function (error) {
        toast.error(JSON.stringify(error.response.data));
        errorLogApi(error, "AddModal", "/recipients/add");
      });
  };

  return (
    <div className="w-full p-3 bg-white rounded-lg shadow-lg">
      <h2 className="mb-4 text-2xl font-semibold">Add Recipient</h2>
      <form className="space-y-4" onSubmit={handleAddSubmit}>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="member_title"
            >
              Title <span className="text-red-500">*</span>
            </label>
            <Select
              name="member_title"
              options={memberTitleOptions}
              styles={style}
              placeholder={placeHolderText("Select Title")}
              onChange={(selected) =>
                dropDownChange(selected, { name: "member_title" })
              }
              value={memberTitleOptions.find(
                (option) => option.value === formData.member_title
              )}
              className="w-full"
              menuPosition="fixed"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="recipient_name"
            >
              First Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="recipient_name"
              id="recipient_name"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="last_name"
            >
              Last Name <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label className="block mb-1 text-sm font-medium" htmlFor="dob">
              Date of Birth <span className="text-red-500">*</span>
            </label>
            <DatePicker
              name="dob"
              onChange={handleDateChange}
              className="w-full h-9"
              format="YYYY-MM-DD"
              required
            />
          </div>
          <div className="w-full md:w-1/3">
            <label className="block mb-1 text-sm font-medium" htmlFor="gender">
              Gender <span className="text-red-500">*</span>
            </label>
            <Select
              name="gender"
              options={genderOptions}
              styles={style}
              placeholder={placeHolderText("Select Gender")}
              onChange={(selected) =>
                setFormData({ ...formData, gender: selected })
              }
              value={formData.gender}
              className="w-full"
              menuPosition="fixed"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="registration_number"
            >
              Registration Number
            </label>
            <input
              type="text"
              name="registration_number"
              id="registration_number"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="treating_doctor"
            >
              Treating Doctor
            </label>
            <input
              type="text"
              name="treating_doctor"
              id="treating_doctor"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/2">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="blood_group"
            >
              Blood Group <span className="text-red-500">*</span>
            </label>
            <Select
              name="blood_group"
              options={bloodgroupOptions}
              styles={style}
              placeholder={placeHolderText("Select Blood Group")}
              onChange={(selected) =>
                setFormData({ ...formData, blood_group: selected })
              }
              value={formData.blood_group}
              className="w-full"
              menuPosition="fixed"
            />
          </div>
        </div>
        <GeographicalComponent
          setFormData={setFormData}
          formData={formData}
          handleChange={handleChange}
        />
        <div className="md:flex md:space-x-4">
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="primary_mobile"
            >
              Primary Mobile <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="primary_mobile"
              id="primary_mobile"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
              required
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="secondary_mobile"
            >
              Secondary Mobile
            </label>
            <input
              type="text"
              name="secondary_mobile"
              id="secondary_mobile"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
          <div className="w-full md:w-1/3">
            <label
              className="block mb-1 text-sm font-medium"
              htmlFor="whatsapp_number"
            >
              WhatsApp Number
            </label>
            <input
              type="text"
              name="whatsapp_number"
              id="whatsapp_number"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded focus:outline-none"
            />
          </div>
        </div>
        <div className="w-full">
          <label className="block mb-1 text-sm font-medium" htmlFor="comments">
            Comments
          </label>
          <textarea
            name="comments"
            id="comments"
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded focus:outline-none"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            className="px-4 py-2 text-white bg-gray-500 rounded-md shadow-sm btn btn-secondary hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            onClick={() => setShowAddModal(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-4 py-2 text-white bg-indigo-600 rounded-md shadow-sm btn btn-primary hover:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Add Recipient
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddModal;
