import React from "react";

const ViewModal = ({ currentRecord }) => {
  const {
    gender,
    blood_group,
    recipient_code,
    member_title,
    recipient_name,
    last_name,
    dob,
    treating_doctor,
    registration_number,
    address,
    pincode,
    primary_mobile,
    secondary_mobile,
    whatsapp_number,
    email,
    comments,
    name,
  } = currentRecord;

  const calculateAge = (birthDateString) => {
    const birthDate = new Date(birthDateString);
    const ageDiffMs = Date.now() - birthDate.getTime();
    const ageDate = new Date(ageDiffMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const age = calculateAge(dob);

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <div className="flex items-center justify-between pb-4 mb-6 border-b border-gray-200">
        <div>
          <h2 className="text-2xl font-semibold text-gray-800">{name}</h2>
          <p className="mt-1 text-sm text-gray-600">{recipient_code}</p>
        </div>
        <div className="flex items-center space-x-2">
          <Badge color="blue">{gender.label}</Badge>
          <Badge color="red">{blood_group.label}</Badge>
          <Badge color="green">{`${age} years`}</Badge>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <InfoSection title="Personal Information">
          <InfoItem label="Date of Birth" value={dob.slice(0, 10)} />
          <InfoItem label="Address" value={address} />
          <InfoItem label="Pincode" value={pincode} />
        </InfoSection>

        <InfoSection title="Medical Information">
          <InfoItem label="Treating Doctor" value={treating_doctor} />
          <InfoItem label="Registration Number" value={registration_number} />
        </InfoSection>

        <InfoSection title="Contact Information">
          <InfoItem label="Primary Mobile" value={primary_mobile} />
          <InfoItem label="Secondary Mobile" value={secondary_mobile} />
          <InfoItem label="WhatsApp Number" value={whatsapp_number} />
          <InfoItem label="Email" value={email} />
        </InfoSection>

        <InfoSection title="Additional Information">
          <InfoItem label="Comments" value={comments} />
        </InfoSection>
      </div>
    </div>
  );
};

const Badge = ({ children, color }) => {
  const colorClasses = {
    blue: "bg-blue-100 text-blue-800",
    red: "bg-red-100 text-red-800",
    green: "bg-green-100 text-green-800",
  };

  return (
    <span
      className={`inline-block px-2 py-1 text-xs font-semibold rounded-full ${colorClasses[color]}`}
    >
      {children}
    </span>
  );
};

const InfoSection = ({ title, children }) => (
  <div className="p-4 rounded-md bg-gray-50">
    <h3 className="mb-3 text-lg font-medium text-gray-800">{title}</h3>
    <div className="space-y-2">{children}</div>
  </div>
);

const InfoItem = ({ label, value }) => (
  <div className="flex flex-col">
    <span className="text-sm font-medium text-gray-600">{label}</span>
    <span className="text-base text-gray-800">{value || "N/A"}</span>
  </div>
);

export default ViewModal;
