import React, { useState } from "react";
import {
  FaInbox,
  FaPaperPlane,
  FaDraftingCompass,
  FaChevronDown,
  FaPen,
  FaSearch,
  FaVideo,
  FaCalendarAlt,
  FaClock,
} from "react-icons/fa";
import "./MailboxBrowserChild.css";

const MailboxBrowserChild = () => {
  const [selectedMailbox, setSelectedMailbox] = useState("Inbox");
  const [selectedEmail, setSelectedEmail] = useState(null);

  // Updated mock data for emails
  const emails = [
    {
      id: 1,
      subject: "Meeting Scheduled: Consultant and Organ Donor Discussion",
      sender: "dr.smith@hospital.com",
      preview:
        "A meeting has been set for next Tuesday at 2 PM to discuss specific details with the consultant and potential organ donor. Please ensure all relevant medical records are available.",
    },
    {
      id: 2,
      subject: "Urgent: Additional Medical Information Required",
      sender: "nurse.johnson@hospital.com",
      preview:
        "We need more detailed information about the patient's medical history. Please fill out the attached form and send it back as soon as possible.",
    },
    {
      id: 3,
      subject: "Transplant Coordinator Update",
      sender: "coordinator@transplant-center.org",
      preview:
        "I've received the latest test results. We need to schedule a follow-up appointment to discuss the next steps in the donation process.",
    },
    {
      id: 4,
      subject: "Donor Family Support Group Meeting",
      sender: "support@donorfamilies.org",
      preview:
        "Our monthly donor family support group meeting is scheduled for this Saturday at 10 AM. We'll be discussing coping strategies and sharing experiences.",
    },
    {
      id: 5,
      subject: "Organ Preservation Protocol Update",
      sender: "research@medical-center.edu",
      preview:
        "We've updated our organ preservation protocol based on recent research findings. Please review the attached document and implement these changes immediately.",
    },
  ];

  const mailboxOptions = [
    { value: "Inbox", label: "Inbox", icon: <FaInbox /> },
    { value: "Sent", label: "Sent", icon: <FaPaperPlane /> },
    { value: "Draft", label: "Draft", icon: <FaDraftingCompass /> },
  ];

  return (
    <div className="mailbox-container">
      <div className="mailbox-header">
        <div className="header-left">
          <h1>Mailbox</h1>
          <div className="mailbox-dropdown">
            <div
              className="dropdown-header"
              onClick={() =>
                document
                  .getElementById("dropdown-options")
                  .classList.toggle("show")
              }
            >
              {
                mailboxOptions.find(
                  (option) => option.value === selectedMailbox
                ).icon
              }
              <span>{selectedMailbox}</span>
              <FaChevronDown />
            </div>
            <div id="dropdown-options" className="dropdown-options">
              {mailboxOptions.map((option) => (
                <div
                  key={option.value}
                  className="dropdown-item"
                  onClick={() => {
                    setSelectedMailbox(option.value);
                    document
                      .getElementById("dropdown-options")
                      .classList.remove("show");
                  }}
                >
                  {option.icon}
                  <span>{option.label}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="header-right">
          <div className="search-bar">
            <FaSearch className="search-icon" />
            <input type="text" placeholder="Search emails" />
          </div>
          <button className="compose-button">
            <FaPen /> Compose
          </button>
        </div>
      </div>
      <div className="mailbox-content">
        <div className="mailbox-sidebar">
          <div className="email-list">
            {emails.map((email) => (
              <div
                key={email.id}
                className={`email-item ${
                  selectedEmail === email.id ? "selected" : ""
                }`}
                onClick={() => setSelectedEmail(email.id)}
              >
                <div className="email-avatar">
                  {email.sender[0].toUpperCase()}
                </div>
                <div className="email-details">
                  <div className="email-subject">{email.subject}</div>
                  <div className="email-sender">{email.sender}</div>
                  <div className="email-preview">{email.preview}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="email-content">
          {selectedEmail ? (
            <div className="selected-email">
              <h2>{emails.find((e) => e.id === selectedEmail).subject}</h2>
              <p>
                <strong>From:</strong>{" "}
                {emails.find((e) => e.id === selectedEmail).sender}
              </p>
              <p>{emails.find((e) => e.id === selectedEmail).preview}</p>

              {/* Add this new section for meeting details */}
              {emails
                .find((e) => e.id === selectedEmail)
                .subject.toLowerCase()
                .includes("meeting") && (
                <div className="meeting-details">
                  <div className="meeting-link">
                    <FaVideo />
                    <a
                      href="https://meet.google.com/abc-defg-hij"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Join Google Meet
                    </a>
                  </div>
                  <div className="meeting-card">
                    <div className="meeting-date">
                      <FaCalendarAlt />
                      <span>Tuesday, May 25, 2023</span>
                    </div>
                    <div className="meeting-time">
                      <FaClock />
                      <span>2:00 PM - 3:00 PM</span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="no-email-selected">
              <p>Select an email to view its content</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MailboxBrowserChild;
