import React from "react";
import { useNavigate } from "react-router-dom";
import "./ErrorPages.css";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";

const Unauthorised = ({ message }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (localStorage.getItem("token")) {
      navigate(-1);
    } else {
      navigate("/login");
    }
  };

  return (
    <MainTemplate>
      <CardBackground>
        <div className="error-page unauthorised">
          <div className="error-content">
            <h1 className="error-code">401</h1>
            <h2 className="error-message">Unauthorised Access</h2>
            {message ? (
              <p className="error-description">{message}</p>
            ) : (
              <p className="error-description">
                You don't have permission to access this page. Please log in or
                contact the administrator.
              </p>
            )}
            <div className="action-buttons">
              <button onClick={handleNavigation} className="btn btn-primary">
                {localStorage.getItem("token") ? "Go Back" : "Log In"}
              </button>
              <button
                onClick={() => navigate("/")}
                className="btn btn-secondary"
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </CardBackground>
    </MainTemplate>
  );
};

export default Unauthorised;
