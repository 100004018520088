import React from "react";
import {
  FiClock,
  FiMapPin,
  FiUser,
  FiCalendar,
  FiHeart,
  FiTruck,
  FiPhone,
} from "react-icons/fi";

const meetings = [
  {
    id: 1,
    title: "Meeting with Donor Family",
    date: "2024-09-24",
    time: "10:00 AM",
    duration: "1 hour",
    location: "Family Consultation Room",
    attendees: [
      "Dr. Sarah Johnson",
      "Social Worker: Mike Davis",
      "Donor Family",
    ],
    type: "donor-family",
  },
  {
    id: 2,
    title: "Transplant Consultation",
    date: "2024-09-24",
    time: "02:00 PM",
    duration: "1.5 hours",
    location: "Clinic Room 305",
    attendees: [
      "Dr. Emily Chen",
      "Recipient: John Smith",
      "Transplant Coordinator",
    ],
    type: "consultation",
  },
  {
    id: 3,
    title: "Organ Transport Arrangement",
    date: "2024-09-25",
    time: "08:30 AM",
    duration: "45 min",
    location: "Logistics Office",
    attendees: ["Transport Coordinator", "Flight Team Lead"],
    type: "transport",
  },
  //   {
  //     id: 4,
  //     title: "Emergency Transplant Team Briefing",
  //     date: "2024-09-25",
  //     time: "11:00 AM",
  //     duration: "30 min",
  //     location: "OR Conference Room",
  //     attendees: ["Dr. Alex Turner", "Surgical Team", "Anesthesiologist"],
  //     type: "team-briefing",
  //   },
];

const getIcon = (type) => {
  switch (type) {
    case "donor-family":
      return <FiHeart className="text-pink-500" />;
    case "consultation":
      return <FiPhone className="text-blue-500" />;
    case "transport":
      return <FiTruck className="text-green-500" />;
    case "team-briefing":
      return <FiUser className="text-purple-500" />;
    default:
      return <FiCalendar className="text-gray-500" />;
  }
};

const CalendarModal = () => {
  return (
    <div className="max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
      <h2 className="mb-6 text-3xl font-bold text-gray-800">
        Calendar & Schedules
      </h2>
      <div className="space-y-6">
        {meetings.map((meeting) => (
          <div
            key={meeting.id}
            className="p-4 transition-shadow duration-300 border-l-4 border-indigo-500 rounded-lg shadow-md bg-gray-50 hover:shadow-lg"
          >
            <div className="flex items-center justify-between mb-2">
              <h3 className="flex items-center text-xl font-semibold text-indigo-600">
                {getIcon(meeting.type)}
                <span className="ml-2">{meeting.title}</span>
              </h3>
              <span className="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                {meeting.duration}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-center text-gray-600">
                <FiCalendar className="mr-2" />
                <span>{meeting.date}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <FiClock className="mr-2" />
                <span>{meeting.time}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <FiMapPin className="mr-2" />
                <span>{meeting.location}</span>
              </div>
              <div className="flex items-center text-gray-600">
                <FiUser className="mr-2" />
                <span>{meeting.attendees.length} attendees</span>
              </div>
            </div>
            <div className="mt-4">
              <h4 className="mb-2 text-sm font-medium text-gray-700">
                Attendees:
              </h4>
              <div className="flex flex-wrap -m-1">
                {meeting.attendees.map((attendee, index) => (
                  <span
                    key={index}
                    className="m-1 bg-gray-200 text-gray-700 text-xs font-medium px-2.5 py-0.5 rounded-full"
                  >
                    {attendee}
                  </span>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarModal;
