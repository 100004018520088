import React from "react";
import { useNavigate } from "react-router-dom";
import "./Page404.css";
import MainTemplate from "../../components/MainTemplate/MainTemplate";
import CardBackground from "../../components/MainTemplate/CardBackground";

const Page404 = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate("/");
  };

  return (
    <MainTemplate>
      <CardBackground>
        <div className="error-page page-404">
          <div className="error-content">
            <h1 className="error-code">404</h1>
            <h2 className="error-message">Page Not Found</h2>
            <p className="error-description">
              Oops! The page you're looking for doesn't exist or has been moved.
            </p>
            <div className="action-buttons">
              <button onClick={goBack} className="btn btn-secondary">
                Go Back
              </button>
              <button onClick={goHome} className="btn btn-primary">
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </CardBackground>
    </MainTemplate>
  );
};

export default Page404;
