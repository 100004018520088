import React from "react";
import {
  FaUser,
  FaEnvelope,
  FaMobile,
  FaBuilding,
  FaIdCard,
  FaClock,
} from "react-icons/fa";

const ViewModal = ({ currentRecord }) => {
  const jsonData = currentRecord;
  const displayKeys = [
    { key: "full_name", icon: <FaUser />, custom: true },
    { key: "mobile", icon: <FaMobile /> },
    { key: "email", icon: <FaEnvelope /> },
    { key: "organization", icon: <FaBuilding /> },
    { key: "subscription_type", icon: <FaIdCard /> },
    { key: "createdAt", icon: <FaClock /> },
    { key: "updatedAt", icon: <FaClock /> },
  ];

  const formatKey = (key) => {
    return key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase());
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleString();
  };

  const getDisplayValue = (key) => {
    if (key === "full_name") {
      return (
        `${jsonData.member_title || ""} ${jsonData.first_name || ""} ${
          jsonData.last_name || ""
        }`.trim() || "N/A"
      );
    }
    if (key.includes("At")) {
      return formatDate(jsonData[key]);
    }
    return jsonData[key] || "N/A";
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow-lg">
      <h2 className="mb-6 text-2xl font-bold text-center text-gray-800">
        Consultant Details
      </h2>
      <div className="overflow-x-auto">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2 text-left text-gray-700">Field</th>
              <th className="px-4 py-2 text-left text-gray-700">Value</th>
            </tr>
          </thead>
          <tbody>
            {displayKeys.map(({ key, icon }, index) => (
              <tr
                key={key}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td className="px-4 py-3 font-semibold text-gray-700">
                  <div className="flex items-center">
                    <span className="mr-2 text-blue-500">{icon}</span>
                    {formatKey(key)}
                  </div>
                </td>
                <td className="px-4 py-3 text-gray-600">
                  {getDisplayValue(key)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewModal;
