import React from "react";
import {
  FaHospital,
  FaMapMarkerAlt,
  FaPhone,
  FaEnvelope,
  FaIdCard,
  FaGlobe,
  FaCity,
  FaComments, // Add this import for the comments icon
} from "react-icons/fa";

const ViewModal = ({ currentRecord }) => {
  const {
    hospital_name,
    hospital_code,
    address,
    contactNumber,
    email,
    registrationNumber,
    country,
    state,
    city,
    createdAt,
    updatedAt,
    comments, // Add this line to destructure the comments field
  } = currentRecord;

  const InfoRow = ({ icon, label, value }) => (
    <div className="flex items-center mb-4">
      {icon}
      <div className="ml-4">
        <p className="text-sm font-medium text-gray-500">{label}</p>
        <p className="text-lg font-semibold text-gray-900">{value}</p>
      </div>
    </div>
  );

  return (
    <div className="max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-xl">
      <h2 className="mb-6 text-2xl font-bold text-gray-900">{hospital_name}</h2>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <InfoRow
          icon={<FaHospital className="text-xl text-blue-500" />}
          label="Hospital Code"
          value={hospital_code}
        />
        <InfoRow
          icon={<FaMapMarkerAlt className="text-xl text-red-500" />}
          label="Address"
          value={address}
        />
        <InfoRow
          icon={<FaPhone className="text-xl text-green-500" />}
          label="Contact Number"
          value={contactNumber}
        />
        <InfoRow
          icon={<FaEnvelope className="text-xl text-purple-500" />}
          label="Email"
          value={email}
        />
        <InfoRow
          icon={<FaIdCard className="text-xl text-yellow-500" />}
          label="Registration Number"
          value={registrationNumber}
        />
        <InfoRow
          icon={<FaGlobe className="text-xl text-indigo-500" />}
          label="Country"
          value={country.label}
        />
        <InfoRow
          icon={<FaCity className="text-xl text-pink-500" />}
          label="State"
          value={state.label}
        />
        <InfoRow
          icon={<FaCity className="text-xl text-orange-500" />}
          label="City"
          value={city.label}
        />
        <InfoRow
          icon={<FaComments className="text-xl text-teal-500" />}
          label="Comments"
          value={comments || "No comments available"}
        />
      </div>
      <div className="pt-4 mt-6 border-t border-gray-200">
        <p className="text-sm text-gray-500">
          Created: {new Date(createdAt).toLocaleString()}
        </p>
        <p className="text-sm text-gray-500">
          Last Updated: {new Date(updatedAt).toLocaleString()}
        </p>
      </div>
    </div>
  );
};

export default ViewModal;
