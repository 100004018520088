import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDown, ChevronUp, ArrowLeft, ArrowRight } from "react-feather";
import {
  FaUserMd,
  FaIdCard,
  FaTint,
  FaDna,
  FaVial,
  FaChartBar,
  FaUserInjured,
  FaHandHoldingHeart,
} from "react-icons/fa";

import HLATypingMatchResultComponent from "./HLATypingMatchResultComponent";
import SingleAntigenBeadTest from "./SingleAntigenBeadTest";
import CREGAnalysisComponent from "./CREGAnalysisComponent";
import MatchPairOverallScore from "./MatchPairOverallScore";

const MatchDisplay = ({ pairs }) => {
  let navigate = useNavigate();
  const [expandedPair, setExpandedPair] = useState(null);

  const toggleExpand = (index) => {
    setExpandedPair(expandedPair === index ? null : index);
  };

  return (
    <div className="container p-1 mx-auto md:p-4">
      <h1 className="mb-6 text-3xl font-bold text-center text-indigo-700">
        Cross-Match Results: {pairs.length} Pair{pairs.length !== 1 ? "s" : ""}{" "}
        Found
      </h1>
      {pairs.map((pair, index) => (
        <MatchPairCard
          key={index}
          pair={pair}
          index={index}
          isExpanded={expandedPair === index}
          onToggle={() => toggleExpand(index)}
          onDeepAnalyze={() => navigate("/v1/deep-analyze", { state: pair })}
        />
      ))}
    </div>
  );
};

const MatchPairCard = ({
  pair,
  index,
  isExpanded,
  onToggle,
  onDeepAnalyze,
}) => {
  const [overallMatchScore, setOverallMatchScore] = useState({
    primary_pair: {
      hlaTypingMatchScore: 0,
      sabTestMatchScore: 0,
      cregMatchScore: 0,
    },
    secondary_pair: {
      hlaTypingMatchScore: 0,
      sabTestMatchScore: 0,
      cregMatchScore: 0,
    },
  });

  return (
    <div
      className={`mb-4 overflow-hidden bg-white rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-[1.01] cursor-pointer ${
        isExpanded ? "bg-indigo-50 border-2 border-indigo-200" : ""
      }`}
    >
      <div className="flex items-center justify-between p-4 bg-gradient-to-r from-blue-50 to-purple-50">
        <h2 className="text-xl font-semibold text-indigo-700">
          Match Pair {index + 1}
        </h2>
        <div className="flex items-center space-x-2">
          <button
            onClick={onDeepAnalyze}
            className="px-3 py-1 text-sm text-white transition duration-300 bg-purple-500 rounded-md hover:bg-purple-600"
          >
            <i className="mdi mdi-stethoscope"></i>&nbsp; Deep Analyze
          </button>
          <button
            onClick={onToggle}
            className="p-1 text-gray-500 transition duration-300 rounded-full hover:bg-gray-200"
          >
            {isExpanded ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
          </button>
        </div>
      </div>
      <div className={`p-4 ${isExpanded ? "bg-indigo-50" : "bg-gray-50"}`}>
        <MatchSummary pair={pair} />
        <div className="mt-2 text-sm text-gray-600">
          <p>
            {/* {JSON.stringify(overallMatchScore)} */}
            <MatchPairOverallScore pair={pair} />
            {/* Primary Pair Score:{" "}
            {calculateTotalScore(overallMatchScore.primary_pair)} */}
          </p>
          {/* <p>
            Secondary Pair Score:{" "}
            {calculateTotalScore(overallMatchScore.secondary_pair)}
          </p> */}
        </div>
      </div>
      {isExpanded && <ExpandedDetails pair={pair} />}
    </div>
  );
};

// Helper function to calculate total score
const calculateTotalScore = (scores) => {
  return Object.values(scores).reduce((sum, score) => sum + score, 0);
};

const MatchSummary = ({ pair }) => (
  <div className="flex flex-col justify-between text-sm md:flex-row">
    <PrimaryPairSummary
      recipientLabel="Primary Recipient"
      recipient={pair.primaryRecipient}
      donorLabel="Secondary Donor"
      donor={pair.secondaryDonor}
    />
    <div className="hidden mx-4 border-r border-gray-300 md:block"></div>
    <SecondaryPairSummary
      recipientLabel="Secondary Recipient"
      recipient={pair.secondaryRecipient}
      donorLabel="Primary Donor"
      donor={pair.primaryDonor}
    />
  </div>
);

const PrimaryPairSummary = ({
  recipientLabel,
  recipient,
  donorLabel,
  donor,
}) => (
  <div className="flex flex-col md:w-[45%]">
    <div className="flex items-center justify-between mb-2">
      <SummaryPerson
        label={recipientLabel}
        person={recipient}
        code={recipient.recipient_code}
        bgColor="bg-blue-100"
        person_type="R"
      />
      <ArrowIndicator className="hidden md:flex" />
      <SummaryPerson
        label={donorLabel}
        person={donor}
        code={donor.donor_code}
        bgColor="bg-green-100"
        person_type="D"
      />
    </div>
    {/* <div className="text-xs text-center text-gray-500">
      Blood Type: {getBloodGroup(recipient)} → {getBloodGroup(donor)}
    </div> */}
  </div>
);

const SecondaryPairSummary = ({
  recipientLabel,
  recipient,
  donorLabel,
  donor,
}) => (
  <div className="flex flex-col md:w-[45%]">
    <div className="flex items-center justify-between mb-2">
      <SummaryPerson
        label={recipientLabel}
        person={recipient}
        code={recipient.recipient_code}
        bgColor="bg-green-100"
        person_type="R"
      />
      <ArrowIndicator className="hidden md:flex" />
      <SummaryPerson
        label={donorLabel}
        person={donor}
        code={donor.donor_code}
        bgColor="bg-blue-100"
        person_type="D"
      />
    </div>
    {/* <div className="text-xs text-center text-gray-500">
      Blood Type: {getBloodGroup(recipient)} → {getBloodGroup(donor)}
    </div> */}
  </div>
);

const SummaryPerson = ({ label, person, code, bgColor, person_type }) => (
  <div
    className={`${bgColor} rounded-lg p-4 m-2 flex-1 shadow-lg flex flex-col md:flex-row`}
  >
    <div className="flex flex-col items-center justify-center mr-4 ">
      {person_type === "R" ? (
        <FaUserInjured className="w-10 h-10 text-indigo-500 sm:w-12 sm:h-12" />
      ) : (
        <FaHandHoldingHeart className="w-10 h-10 text-green-500 sm:w-12 sm:h-12" />
      )}
    </div>
    <div className="flex-1 mt-2 md:mt-0">
      <p className="flex items-center mb-2 text-xs font-semibold text-gray-700">
        {/* <FaUserMd className="mr-1" />  */}
        {label}
      </p>
      <p className="mb-2 text-sm font-medium text-gray-800">
        {getFullName(person)}
      </p>
      <p className="flex items-center mb-1 text-xs text-gray-600">
        <FaIdCard className="mr-1" /> {code}
      </p>
      <p className="flex items-center text-xs text-gray-600">
        <FaTint className="mr-1 text-red-500 " />
        {getBloodGroup(person)}
      </p>
    </div>
  </div>
);

const ExpandedDetails = ({ pair }) => (
  <div className="p-4 space-y-8">
    <MatchPairDetails
      title="Primary Match"
      recipient={pair.primaryRecipient}
      donor={pair.secondaryDonor}
      icon={<PrimaryMatchIcon />}
      bgColor="bg-blue-100"
    />
    <MatchPairDetails
      title="Secondary Match"
      recipient={pair.secondaryRecipient}
      donor={pair.primaryDonor}
      icon={<SecondaryMatchIcon />}
      bgColor="bg-green-100"
    />
  </div>
);

const MatchPairDetails = ({ title, recipient, donor, icon, bgColor }) => (
  <div className={`${bgColor} rounded-lg p-6 shadow-md`}>
    <div className="flex items-center mb-4 space-x-3">
      {icon}
      <h3 className="text-xl font-semibold text-indigo-800">{title}</h3>
    </div>
    <div className="flex flex-col items-center justify-between gap-4 mb-4 md:flex-row">
      <MatchCard
        title="Recipient"
        person={recipient}
        code={recipient.recipient_code}
        consultant={recipient.consultant_id}
        bgColor="bg-white"
        textColor="text-blue-800"
        person_type="R"
      />
      <ArrowIndicator />
      <MatchCard
        title="Donor"
        person={donor}
        code={donor.donor_code}
        consultant={donor.consultant_id}
        bgColor="bg-white"
        textColor="text-green-800"
        person_type="D"
      />
    </div>
    <MatchDetails recipient={recipient._id} donor={donor._id} />
  </div>
);

const PrimaryMatchIcon = () => (
  <svg
    className="w-8 h-8 text-indigo-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const SecondaryMatchIcon = () => (
  <svg
    className="w-8 h-8 text-indigo-600"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M13 10V3L4 14h7v7l9-11h-7z"
    />
  </svg>
);

const MatchDetails = ({
  recipient,
  donor,
  setOverallMatchScore,
  overallMatchScore,
}) => (
  <div className="space-y-4">
    <DetailSection
      title="HLA Match Details"
      icon={<FaDna className="text-xl text-blue-500" />}
    >
      <HLATypingMatchResultComponent recipient={recipient} donor={donor} />
    </DetailSection>
    <DetailSection
      title="Single Antigen Bead Test"
      icon={<FaVial className="text-xl text-green-500" />}
    >
      <SingleAntigenBeadTest recipient={recipient} donor={donor} />
    </DetailSection>
    <DetailSection
      title="CREG Analysis"
      icon={<FaChartBar className="text-xl text-purple-500" />}
    >
      <CREGAnalysisComponent recipient={recipient} donor={donor} />
    </DetailSection>
  </div>
);

const DetailSection = ({ title, icon, children }) => (
  <div className="p-3 bg-white rounded-lg shadow-sm">
    <h4 className="flex items-center mb-2 font-semibold text-gray-700 text-md">
      {icon}
      <span className="ml-2">{title}</span>
    </h4>
    <div className="text-sm text-gray-600">{children}</div>
  </div>
);

const MatchCard = ({
  title,
  person,
  bgColor,
  textColor,
  code,
  consultant,
  person_type,
}) => {
  return (
    <div
      className={`${bgColor} rounded-lg p-4 shadow-md transition-transform hover:scale-105 w-full md:w-5/12 relative`}
    >
      <div className="flex items-start justify-between mb-3">
        <h3 className={`text-lg font-semibold ${textColor}`}>{title}</h3>
        {person_type === "R" ? (
          <FaUserInjured className="w-8 h-8 text-indigo-500 " />
        ) : (
          <FaHandHoldingHeart className="w-8 h-8 text-green-500" />
        )}
      </div>
      <div className="space-y-2">
        <InfoItem
          icon={<FaIdCard className="w-4 h-4 text-gray-500" />}
          label="Name"
          value={getFullName(person)}
        />
        <InfoItem
          icon={<FaUserMd className="w-4 h-4 text-gray-500" />}
          label="Code"
          value={code}
        />
        <InfoItem
          icon={<FaTint className="w-4 h-4 text-red-500" />}
          label="Blood Group"
          value={getBloodGroup(person)}
        />
        <InfoItem
          icon={<FaUserInjured className="w-4 h-4 text-purple-500" />}
          label="Gender"
          value={getGender(person)}
        />
        <InfoItem
          icon={<FaUserMd className="w-4 h-4 text-blue-500" />}
          label="Consultant"
          value={consultant}
        />
      </div>
    </div>
  );
};

const ArrowIndicator = () => (
  <div className="flex items-center justify-center py-2 md:py-0">
    <ArrowLeft className="mr-1 text-indigo-500" size={20} />
    <ArrowRight className="ml-1 text-indigo-500" size={20} />
  </div>
);

const InfoItem = ({ label, value }) => (
  <p className="flex justify-between text-sm">
    <span className="font-medium">{label}:</span>
    <span className="text-gray-700">{value}</span>
  </p>
);

const getFullName = (person) => {
  if (person.name) return person.name;
  return `${person.member_title || ""} ${
    person.donor_name || person.recipient_name || ""
  } ${person.last_name || ""}`.trim();
};

const getBloodGroup = (person) => {
  return person.bloodgroup || person.blood_group?.label || "N/A";
};

const getGender = (person) => {
  return person.gender_label || person.gender?.label || "N/A";
};

export default MatchDisplay;
